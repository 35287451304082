import api from "../../../dependencies/utils/Api";

import { E_ELINIC_REQUEST, PROCEED_LOGOUT, FORGOT_REQUEST_OTP, LOGIN, REGISTER_DOCTOR, REGISTER_EXPERT, REGISTER_USER, REQUEST_REGISTER_TOP, RESET_PASSWORD } from "../../../constants/ApplicationUrl";

export const authService = {
  async doLogin(params) {
    const response = await api.POST(LOGIN, params)
    console.log(response, ">>>>>")
    const { data: { token, user, message, status, error } = {} } = response;
    if (status) {
      return { message, status, token, user };
    } else {
      return { message, status, error };
    }


  },

  async proceedtologout(params) {
    const response = await api.POST(PROCEED_LOGOUT, params)
    const { data: { token, user, message, status, error } = {} } = response;
    if (status) {
      return { message, status, token, user };
    } else {
      return { message, status, error };
    }


  },

  doLogout(params) {
    return api.POST(LOGIN, params).then((response) => {
      const { data: { message, status, token, user, error } = {} } = response;
      if (status) {
        return { message, status, token, user };
      } else {
        return { message, status, error };
      }

    })
  },

  // doRegisterDoctor(params) {
  //   return api.POSTDATA(REGISTER_DOCTOR, params).then((response) => {
  //     // const { data: { message, status } = {} } = response;
  //     // return { message, status };
  //     return response
  //   });
  // },

  doRegisterDoctor(params) {
    return api.POSTDATA(REGISTER_DOCTOR, params).then((response) => {
      console.log("res----------", response);
      if (response.status === 200) {
        const { data: { message, status } = {} } = response;
        if (status) {
          return { message, status };
        } else {
          return { message, status };
        }
      }
      else {
        return response
      }
    });
  },

  doRegisterUser(params) {
    return api.POST(REGISTER_USER, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  },
  doFranchiseRegister(params) {
    return api.POSTDATA(REGISTER_EXPERT, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  },
  EcliniRequest(params) {
    return api.POST(E_ELINIC_REQUEST, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  },
  PasswordRequestOtp(params) {

    return api.POST(FORGOT_REQUEST_OTP, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  },

  ResetPassword(params) {

    return api.POST(RESET_PASSWORD, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  },
  RequestOTP(params) {
    return api.POST(REQUEST_REGISTER_TOP, params).then((response) => {
      const { data: { message, status } = {} } = response;

      return { message, status };
    });
  }

};
