import api from "../../../dependencies/utils/Api";

import { CREATE_COMPLENT } from "../../../constants/ApplicationUrl";

export const ServiceRequestService = {

    // getItemList(params) {
    //     return api.GET(CREATE_COMPLENT, params).then((response) => {
    //         console.log('service.js file response error',response)
    //         const { data: {message, status, data} = {}} = response    
    //         if(status) {
    //             return {message, data}
    //         }else {

    //         }
    //     })
    // },

    getItemList(params) {
        return api.GET(`${CREATE_COMPLENT}?page=${params.page}&size=${params.size}&fromDate=${params.fromDate}&toDate=${params.toDate}&status=${params.status}&name=${params.name}&mobile=${params.mobile}&email=${params.email}`).then((response) => {
           console.log(response,"res")
            // Check if the response or data is undefined or not an object
            if (!response || !response.data || typeof response.data !== 'object') {
                // Return an object with default values
                return { message: '', status: false, data: [] };
            }

            // Destructure the response and provide default values if keys are missing or undefined
            const { data: { message, status, raiseComplaints,totalData,totalPages } = {} } = response;

            // Return the extracted data with default values
            return { message, status, raiseComplaints,totalData,totalPages };
        });
    },

    
    
    updateStatus(id,params) {
        return api.PUT(`${CREATE_COMPLENT}/${id}`,params).then((response) => {
            const { data: {message, status} = {}} = response    
            if(status) {
                return {message, status}
            }else {

            }
        })
    },
    

}