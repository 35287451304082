import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { createComplaint } from "../dependiencies/action";

function Support(props) {
  const { handleClose, show } = props;
  const { user } = useSelector((state) => state.login);
  const [form, setForm] = useState({});
  const [error, setError] = useState({});



  const handleInputChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };


  const dispatch = useDispatch();

  const validator = () => {
    let errors = {};
    if (!form.title) {
      errors = { ...errors, title: "This field is required!" };
    }

    if (!form.details) {
      errors = { ...errors, details: "This field is required!" };
    }
    setError(errors);
    return errors;
  };

  const handleAdd = (e) => {
    e.preventDefault();
    let vallidate = validator();
    if (!isEmpty(vallidate)) {
      return false;
    }
    dispatch(createComplaint(form)).then(() => {
      handleClose(false)
      setForm({})
    })
  };

  return (
    <>
      <Modal
        show={show}
        onHide={(e) => handleClose(false)}
        backdrop="static"
      >
        <Modal.Header>
          <span>Withdraw Ammount</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <label>Issue</label>
            <select
              name="title"
              value={form.title}
              className="form-select"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}>
              <option value="">--Issue--</option>
              <option value="Technical Issue">Technical Issue</option>
              <option value="Appoinment Issue">Appoinment Issue</option>
              <option value="Payment Related Issue">Payment Related Issue</option>
              <option value="Other">Other</option>
            </select>
            {error && error.title && (
              <span className="text-danger">{error.title}</span>
            )}
            {form.title === "Other" && (
              <div className="form-group mt-3">
                <label htmlFor="otherIssue">Other Issue:</label>
                <input
                  type="text"
                  className="form-control"
                  name="otherTitle"
                  value={form.otherTitle}
                  placeholder="Enter other issue"
                  onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                />
              </div>
            )}

            <div className="form-group mt-3">
              <label htmlFor="description">Description:</label>
              <textarea
                className="form-control"
                name="details"
                value={form.details}
                placeholder="description"
                onChange={(e) => handleInputChange(e.target.name, e.target.value)}
              />
              {error && error.details && (
                <span className="text-danger">{error.details}</span>
              )}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}>
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleAdd(e)}>
            Sumbit
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Support;
