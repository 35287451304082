import React, { useState } from 'react';
import './loginpopup.css'
import { RiQuestionMark } from "react-icons/ri";
import { useNavigate, Link } from "react-router-dom";
import { proceedtologout } from "../dependencies/action";
import { useDispatch } from 'react-redux';



function LoginPage({ onClose, form }) {
    const navigate = useNavigate();
    console.log("form_____________", form);
    const [isPopupOpen, setPopupOpen] = useState(true);
    const dispatch = useDispatch();

    // const ProceedLogin = () => {
    //     setPopupOpen(!isPopupOpen);
    //     onClose()
    // };

    const closePopup = () => {
        setPopupOpen(!isPopupOpen);
        onClose()
    };


    const ProceedLogin = async (e) => {
        try {
            const loginResponse = await dispatch(proceedtologout(form));
            if (loginResponse.status) {
                let rediret = sessionStorage.getItem("redirect_url");
                if (rediret) {
                    sessionStorage.removeItem("redirect_url");
                    navigate(rediret);
                } else {
                    navigate("/dashboard");
                }
            } else {
                // setIsPopupOpen(!isPopupOpen); // Open the popup if login fails
                // alert(isPopupOpen)
                // setError(loginResponse.error);
            }
        } catch (error) {
            // setIsPopupOpen(true)
            console.log(error);
        }
    };

    return (
        <div>
            {isPopupOpen && (
                <div className="popup-background">
                    <div className="popup-inner">
                        <h1><RiQuestionMark /></h1>
                        <p>Are you sure you want to log out from other devices?</p>
                        <div className="buttons">
                            <button className="proceed-btn" onClick={() => ProceedLogin()}>Proceed</button>
                            <button className="cancel-btn" onClick={() => closePopup}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default LoginPage;
