import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { withdraw } from "../dependencies/action";

function WithdrawAmmount(props) {
  const { handleClose, show } = props;
  const { user } = useSelector((state) => state.login);
  const [form, setForm] = useState({});
  const [error, setError] = useState({});

  const handleInputChange = (name, value) => {
    setForm({ ...form, [name]: value });
  };


  const dispatch = useDispatch();
  const validator = () => {
    let errors = {};
    if (!form.amount) {
      errors = { ...errors, amount: "This field is required!" };
    }
    else if (form.amount <= 1000) {
      errors = { ...errors, amount: "Amount must be greater than 1000!" };
    }
    setError(errors);
    return errors;
  };



  const handleAdd = (e) => {
    e.preventDefault();
    let vallidate = validator();
    if (!isEmpty(vallidate)) {
      return false;
    }
    dispatch(withdraw(form)).then(() => {
      handleClose(false)
      setForm({})
    })
  };
  return (
    <>
      <Modal
        show={show}
        onHide={(e) => handleClose(false)}
        backdrop="static"
      >
        <Modal.Header>
          <span>Withdraw Ammount</span>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="form-group">
                <label>Amount</label>
                <input
                  type="number"
                  name="amount"
                  value={form.amount}
                  onChange={(e) =>
                    handleInputChange(e.target.name, e.target.value)
                  }
                  placeholder="Amount"
                  className="form-control"
                />
                {error && error.amount && (
                  <span className="text-danger">{error.amount}</span>
                )}
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}>
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleAdd(e)}>
            Withdraw
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default WithdrawAmmount;
