import { ADD_WALLET, CCAVENUEINCRIPT, DASHBOARD, GET_WALLET, NOTIFICATION, STATE_CITY, STRIPE_PAYMENT_SESSION, USER_AVALIABLE_STATUS, VALIDATE_PAYMENT_STATUS, GET_INCOME_WALLET, GET_TIME_SLOT } from "../../../constants/ApplicationUrl";
import api from "../../../dependencies/utils/Api";

export const WalletService = {
  // getWallet(params) {
  //   return api.GET(GET_WALLET).then((response) => {
  //     const { data: { balance, transactions } = {} } = response;
  //     return { balance, transactions }

  //   });
  // },

  getWallet(params) {
    return api.GET(GET_WALLET).then((response) => {
      // Check if the response or its data is undefined or not an object
      // if (!response || !response.data || typeof response.data !== 'object') {
      //     // Return an object with default values
      //     return { balance: 0, transactions: [] };
      // }

      // Destructure the response and provide default values if keys are missing or undefined
      const { data: { balance = 0, transactions = [] } = {} } = response.data;

      // Return the extracted data with default values
      return { balance, transactions };
    }).catch((error) => {
      // Handle any errors that occur during the request
      console.error('Error in getWallet:', error);
      // Return an object with default values
      return { balance: 0, transactions: [] };
    });
  },


  getIncomeWallet(params) {
    return api.GET(GET_INCOME_WALLET).then((response) => {
      console.log('income wlllet ', response)
      // alert('we')
      // Check if the response or its data is undefined or not an object
      if (!response || !response.data || typeof response.data !== 'object') {
        // Return an object with default values
        return { balance: 0, transactions: [] };
      }

      // Destructure the response and provide default values if keys are missing or undefined
      const { data: { balance = 0, transactions = [] } = {} } = response.data;

      // Return the extracted data with default values
      return { balance, transactions };
    }).catch((error) => {
      // Handle any errors that occur during the request
      console.error('Error in getWallet:', error);
      // Return an object with default values
      return { balance: 0, transactions: [] };
    });
  },



  //


  // getTimeSlot(name,id) {
  //   return api.POST(`${GET_TIME_SLOT}/${id}`, {dateTime:name}).then((response) => {
  //     console.log('get time slot in main function ',name ,id,response)
  //     // alert('we')
  //       // Check if the response or its data is undefined or not an object
  //       if (!response || !response.data || typeof response.data !== 'object') {
  //           // Return an object with default values
  //           return { balance: 0, transactions: [] };
  //       }

  //       // Destructure the response and provide default values if keys are missing or undefined
  //       const { data: { balance = 0, transactions = [] } = {} } = response.data;

  //       // Return the extracted data with default values
  //       return { balance, transactions };
  //   }).catch((error) => {
  //       // Handle any errors that occur during the request
  //       console.error('Error in getWallet:', error);
  //       // Return an object with default values
  //       return { balance: 0, transactions: [] };
  //   });
  // },



  getTimeSlot(name, id) {
    return api.POST(`${GET_TIME_SLOT}/${id}`, { dateTime: name })
      .then((response) => {
        console.log('get time slot in main function ', name, id, response);

        // Check if the response or its data is undefined or not an object
        if (!response || !response.data || typeof response.data !== 'object') {
          // Return an object with default values
          return { extractedOfflineTimes: [], extractedOnlineTimes: [] };
        }

        // Destructure the response and provide default values if keys are missing or undefined
        const {
          data: {
            extractedOfflineTimes = [],
            extractedOnlineTimes = [],
            message = "no time slot",
            status = true
          } = {}
        } = response;
        return { extractedOfflineTimes, extractedOnlineTimes, message, status };
      })
      .catch((error) => {
        console.error('Error in getTimeSlot:', error);
        return { extractedOfflineTimes: [], extractedOnlineTimes: [] };
      });
  },



  setWallet(params) {
    return api.POST(ADD_WALLET, params).then((response) => {
      const { data: { wallet: { balance, transactions }, status, message } = {} } = response;
      return { balance, status, message, transactions }
    });
  },
  getDashboard(params) {
    return api.GET(DASHBOARD, params).then((response) => {
      const { data: { status, message, data } = {} } = response;
      return { status, message, data }

    });
  },
  getState() {
    return api.GET(STATE_CITY).then((response) => {
      const { data: { status, message, data } = {} } = response;
      return { status, message, data }

    });
  },


  createPaymentSession(params) {
    return api.POST(STRIPE_PAYMENT_SESSION, params).then((response) => {
      const { data: { status, message, sessionId } = {} } = response;
      return { status, message, sessionId }
    })
  },

  encryptCCAvenueData(params) {
    return api.POST(CCAVENUEINCRIPT, params).then((response) => {
      const { data: { status, data } = {} } = response;
      return { status, data }
    })
  },

  ValidatePaymentSession(sessionId) {
    return api.GET(`${VALIDATE_PAYMENT_STATUS}?sessionId=${sessionId}`).then((response) => {
      const { data: { status, message, data } = {} } = response;
      return { status, message, data }
    })
  },

  getNotification() {
    return api.GET(NOTIFICATION).then((response) => {
      if (!response || typeof response.data !== 'object') {
        return '';
      }
      const { data: { status, message, notifications, totalNotification, readCount, unreadCount } = {} } = response;
      return { status, message, notifications, totalNotification, readCount, unreadCount }
    })
  },

  updateUserAvaliableStatus(id, params) {
    return api.PUT(`${USER_AVALIABLE_STATUS}/${id}`, params).then((response) => {
      const { data: { status, message, data } = {} } = response;
      return { status, message, data }
    })
  }
};
