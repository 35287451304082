import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Api from "../../dependencies/utils/Api";

function PatientTransactionModal({ show, handleClose, doctor, type }) {
  const [transactions, setTransactions] = useState([]);
  const [fromDate, setFromDate] = useState(""); // State for fromDate
  const [toDate, setToDate] = useState(""); // State for toDate
  const [page, setPage] = useState(1); // State for page number
  const [totalPages, setTotalPages] = useState(0); // State for total pages
  const [orderStatus, setOrderStatus] = useState("")

  console.log(transactions)
  useEffect(() => {
    fetchTransactions();
  }, [doctor, fromDate, toDate, page, type]); // Include fromDate, toDate, page, and type in the dependency array

  const fetchTransactions = async () => {
    try {
      // Construct URL with query parameters including fromDate, toDate, page, and type
      const url = `https://api.fever99.com/api/transaction-detail/${doctor._id}?role=${doctor.role}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&status=${orderStatus}&type=${type}`;
      // Fetch data from the API
      const response = await Api.GET(url);
      setTransactions(response.data.transactionDetail);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };

  const clearFilter = () => {
    setFromDate("");
    setToDate("");
    setOrderStatus("");
  };

  const changePage = (newPage) => {
    setPage(newPage);
  };

  const calculateSerialNumber = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  useEffect(() => {
    fetchTransactions();
    setPage(1);
  }, [show, fromDate, toDate, orderStatus]);

  return (
    <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>Transaction Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row m-b-20">
          <div className="col-4">
            <label>Search By Payment Staus</label>
            <select
              className="form-select"
              value={orderStatus}
              onChange={(e) => setOrderStatus(e.target.value)}
            >
              <option value="">--Select Status--</option>
              <option value="">All</option>
              <option value="Success">Success</option>
              <option value="Failure">Failure</option>
              <option value="Timeout">Timeout</option>
            </select>
          </div>
          <div className="col-4">
            <label>From Date</label>
            <input
              type="date"
              name="fromDate"
              value={fromDate}
              className="form-control"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="col-4">
            <label>To Date</label>
            <input
              type="date"
              name="toDate"
              value={toDate}
              min={fromDate}
              className="form-control"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="col-4">
            <button className="btn btn-danger ml-2" onClick={clearFilter}>
              Clear Filter
            </button>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Order Id</th>
              <th>Transaction Date</th>
              <th>Tracking Id</th>
              <th>Bank Ref No.</th>
              <th>Status</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={transaction._id}>
                <td>{calculateSerialNumber(index)}</td>
                <td>{transaction.order_id}</td>
                <td>{transaction.trans_date}</td>
                <td>{transaction.tracking_id}</td>
                <td>{transaction.bank_ref_no}</td>
                <td>{transaction.order_status}</td>
                <td>{transaction.amount}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            {Array.from({ length: totalPages }, (_, i) => i + 1).map((pageNum) => (
              <li key={pageNum} className={`page-item ${page === pageNum ? "active" : ""}`}>
                <button className="page-link" onClick={() => changePage(pageNum)}>
                  {pageNum}
                </button>
              </li>
            ))}
          </ul>
        </nav>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-danger" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default PatientTransactionModal;
