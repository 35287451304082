import React, { useEffect, useState } from "react";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependencies/TableColumn";
import {
  RegisterFranchise,
  RegisterUser,
  getItemList,
  updateFranchise,
  updateUserById,
} from "./dependencies/action";
import { ROLES } from "../../constants/role";
import AddUpdate from "./AddUpdate/addUpdate";
import Update from "./AddUpdate/Update";
import { getStateCity } from "../Dashboard/dependencies/action";
import DoctorDetailsModal from "../Doctors/DoctorDetailsModal/DoctorDetailsModal";
import { useModal } from "../UseModal/UseModal";
import WalletTrackingModal from "../WalletTrackingModal/WalletTrackingModal";
import PatientTransactionModal from "../PatientTransactionModal/PatientTransactionModal";
import DownloadFranchiseData from "./DownloadFranchiseData/DownloadFranchiseData";
import { useNavigate } from "react-router-dom";
function Franchise() {
  const [printPage, setPrintPage] = useState(false)
  const navigate = useNavigate();

  const {
    showModal: showTransactionModal,
    selectedItem: selectedPatient,
    openModal: handleOpenTransactionModal,
    closeModal: handleCloseTransactionModal,
  } = useModal();
  const {
    showModal: showWalletModal,
    selectedItem: selectedDoctorWallet,
    openModal: handleOpenWalletModal,
    closeModal: handleCloseWalletModal,
  } = useModal();

  const {
    showModal: showIncomeWalletModal,
    selectedItem: selectedFranchiseIncomeWallet,
    openModal: handleOpenIncomeWalletModal,
    closeModal: handleCloseIncomeWalletModal,
  } = useModal();


  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDoctor, setSelectedDoctor] = useState(null);
  const handleOpenDetailsModal = (doctor) => {
    console.log(doctor)
    setSelectedDoctor(doctor);
    setShowDetailsModal(true);
  };

  const handleCloseDetailsModal = () => {
    setSelectedDoctor(null);
    setShowDetailsModal(false);
  };

  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    role: ROLES.FRANCHISE,
    filter: "",
    email: "",
    mobile: "",
    activeStatus: "",
    appointment: "",
    availableFor: "",
    fromDate: "",
    toDate: "",
    appointmentStatus: ""
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [SearchQuery, setSearchQuery] = useState("");
  const { ItemList, ItemTotalPage } = useSelector((state) => state.users);
  const dispatch = useDispatch();
  const [showAddUpdate, setShowAddUpdate] = useState(false);
  const [showUpdate, setShowUpdate] = useState(false);
  const [rowData, setRowData] = useState({});
  const [searchString, setSearchString] = useState("");

  const { stateList } = useSelector((state) => state.stateCity);
  const [city, setCity] = useState([]);
  const [stateListSorted, setStateListSorted] = useState([]);
  const [citySorted, setCitySorted] = useState([]);
  useEffect(() => {
    if (stateList) {
      const sortedStates = stateList.slice().sort((a, b) => a.state.localeCompare(b.state));
      setStateListSorted(sortedStates);
    }
  }, [stateList]);

  useEffect(() => {
    if (city) {
      const sortedCities = city.slice().sort();
      setCitySorted(sortedCities);
    }
  }, [city]);
  const [addtionlaFilter, setAdditionalFilter] = useState({
    state: "",
    city: "",
  });

  const handlePageChange = (page) => {
    setQuery({ ...query, page: page, ...addtionlaFilter });
  };

  const handleFromDateChange = (e) => {
    const selectedFromDate = e.target.value;
    setFromDate(selectedFromDate);
    if (toDate && selectedFromDate > toDate) {
      setToDate('');
    }
  };
  const handleToDateChange = (e) => {
    const selectedToDate = e.target.value;
    setToDate(selectedToDate);
  };

  useEffect(() => {
    dispatch(getStateCity());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getItemList({ ...query, ...addtionlaFilter }));
  }, [addtionlaFilter, dispatch, query]);

  const handleAdd = (data) => {
    dispatch(RegisterFranchise(data)).then((res) => {
      if (res.status) {
        setShowAddUpdate(false);
        dispatch(getItemList({ ...query, ...addtionlaFilter }));
      }
    });
  };

  const handleUpdate = (id, data) => {
    dispatch(updateFranchise(id, data)).then((res) => {
      setShowAddUpdate(false);
      dispatch(getItemList({ ...query, ...addtionlaFilter }));
      setRowData(null);
      setShowUpdate(false);
    });
  };
  const HandleEdit = (row) => {
    setRowData(row);
    setShowUpdate(true);
  };
  const HandleCloseUpdate = () => {
    setRowData(null);
    setShowUpdate(false);
  };
  const activateDeactivate = (id, status) => {
    dispatch(updateUserById(id, { status })).then((res) => {
      if (res.status) {
        dispatch(getItemList({ ...query, ...addtionlaFilter }));
      }
    });
  };

  const getRowClass = (row) => {
    if (row.status === "active") {
      return "";
    } else if (row.status === "inactive") {
      return "table-danger";
    }
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setSearchQuery(value);
    setQuery({ ...query, [name]: value });
  };
  const handleStatusChange = (e) => {
    const activeStatus = e.target.value;
    setQuery({ ...query, activeStatus: activeStatus });
  };

  const handleAppointmentChange = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };

  const handleEarningQuery = (e) => {
    const earningQuery = e.target.value;
    setQuery({ ...query, earningQuery: earningQuery });
  };

  const handleStateChange = (e) => {
    const stateId = e.target.value;
    if (!stateId) {
      setCity([]);
      setAdditionalFilter({
        ...addtionlaFilter,
        state: "",
        city: "",
      });
      setQuery({ ...query, state: "", city: "" });
      return;
    }
    const selectedState = stateList.find((state) => state._id === stateId);
    if (selectedState) {
      const cityOptions = selectedState.city;
      setAdditionalFilter({
        ...addtionlaFilter,
        state: selectedState.state,
        city: "",
      });
      setCity(cityOptions);
    }
    setQuery({ ...query, state: stateId, city: "" });
  };

  const handleCityChange = (e) => {
    const selectedCity = e.target.value;
    setAdditionalFilter({ ...addtionlaFilter, city: selectedCity });
    setQuery({ ...query, city: selectedCity });
  };

  const handleApplyDate = (e) => {
    setQuery({ ...query, fromDate: fromDate, toDate: toDate });
  };

  const handleClear = () => {
    setQuery({
      page: 1,
      limit: 10,
      role: ROLES.FRANCHISE,
      filter: "",
      email: "",
      mobile: "",
      activeStatus: "",
      appointment: "",
      availableFor: "",
      fromDate: "",
      toDate: "",
      earningQuery: "",
      appointmentStatus: ""

    });
    setSearchQuery("");
    setFromDate("");
    setToDate("");
    setAdditionalFilter({
      state: "",
      city: "",
    });
    setCity([]);
    setSearchQuery("")
  };
  const handlePrint = () => {
    setPrintPage(true);
    navigate('/downloadFranchiseData');

  };

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div className="col-sm-4 col-3">
            <h4 className="page-title">Franchise</h4>
          </div>
          <div className="row">
            <div className="form-group col-md-3 mt-3">
              <label>Search By Name</label>
              <input
                className="form-control"
                name="filter"
                value={query.filter}
                placeholder="Name"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Email</label>
              <input
                className="form-control"
                name="email"
                value={query.email}
                placeholder="Email"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Mobile</label>
              <input
                className="form-control"
                name="mobile"
                value={query.mobile}
                placeholder="Mobile"
                onChange={handleSearch}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Status</label>
              <select
                className="form-select"
                name="activeStatus"
                value={query.activeStatus}
                onChange={handleStatusChange}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Appointment</label>
              <select
                className="form-select"
                name="appointment"
                value={query.appointment}
                onChange={handleAppointmentChange}
              >
                <option value="">--By Appointment--</option>
                <option value="">All</option>
                <option value="max">Maximum</option>
                <option value="min">Minimum</option>
              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Appointment Status</label>
              <select
                className="form-select"
                name="appointmentStatus"
                value={query.appointmentStatus}
                onChange={handleAppointmentChange}
              >
                <option value="">--By Appointment Status--</option>
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="cancelled">Cancelled</option>
                <option value="completed">Completed</option>
                <option value="rejected">Rejected</option>
                <option value="accepted">Accepted</option>
                <option value="rescheduled">Rescheduled</option>

              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By State</label>
              <select
                className="form-select"
                onChange={(e) => handleStateChange(e)}
              >
                <option value="">--Select State--</option>
                {stateListSorted &&
                  stateListSorted.map((sta, index) => (
                    <option value={sta._id} key={index}>
                      {sta.state}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By City</label>
              <select
                className="form-select"
                onChange={(e) => handleCityChange(e)}
              >
                <option value="">--Select City--</option>
                {citySorted &&
                  citySorted.map((c, index) => (
                    <option value={c} key={index}>
                      {c}
                    </option>
                  ))}
              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>Search By Earning</label>
              <select
                className="form-select"
                name="earningQuery"
                value={query.earningQuery}

                onChange={handleEarningQuery}
              >
                <option value="">--By Earning--</option>
                <option value="">All</option>
                <option value="max">Maximum</option>
                <option value="min">Minimum</option>
              </select>
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                className="form-control"
                value={fromDate}
                onChange={handleFromDateChange}
              />
            </div>
            <div className="form-group col-md-3 mt-3">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                className="form-control"
                value={toDate}
                min={fromDate} // Set the minimum allowed date for "To Date" as the selected "From Date"
                onChange={handleToDateChange}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="col-md-1 mt-4">
              <button
                className="btn btn-sm btn-info"
                style={{ marginTop: "27px" }}
                onClick={(e) => handleApplyDate(e)}
                type="button"
              >
                Apply
              </button>
            </div>
            <div className="form-group col-md-2 mt-5">
              <button
                className="btn btn-secondary"
                onClick={handleClear}
                type="button"
              >
                Clear Filters
              </button>
            </div>
          </div>
          <a
            href="#0"
            className="btn btn-sm btn-success mb-1 mt-3"
            onClick={() => setShowAddUpdate(true)}
          >
            Add Frachise
          </a>
          <button className="btn btn-sm btn-primary mb-1 mt-3 mx-3" onClick={handlePrint}>
            Data
          </button>
          {printPage && <DownloadFranchiseData ItemList={ItemList} />}
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        HandleEdit,
                        activateDeactivate,
                        pageNo: query.page,
                        size: query.size,
                        openModal: handleOpenDetailsModal,
                        openWalletModal: handleOpenWalletModal,
                        openIncomeWalletModal: handleOpenIncomeWalletModal,
                        openTransactionModal: handleOpenTransactionModal,
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                      getRowClass={getRowClass}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddUpdate
        show={showAddUpdate}
        handleClose={(value) => setShowAddUpdate(value)}
        handleAdd={(data) => handleAdd(data)}
      />

      <Update
        show={showUpdate}
        handleClose={(value) => HandleCloseUpdate(value)}
        handleUpdate={(id, data) => handleUpdate(id, data)}
        data={{ rowData: rowData }}
      />
      <DoctorDetailsModal
        show={showDetailsModal}
        handleClose={handleCloseDetailsModal}
        doctor={selectedDoctor}
      />

      <WalletTrackingModal
        show={showWalletModal}
        handleClose={handleCloseWalletModal}
        doctor={selectedDoctorWallet}
        type="wallet"
      />
      <WalletTrackingModal
        show={showIncomeWalletModal}
        handleClose={handleCloseIncomeWalletModal}
        doctor={selectedFranchiseIncomeWallet}
        type="incomeWallet"
      />
      <PatientTransactionModal
        show={showTransactionModal}
        handleClose={handleCloseTransactionModal}
        doctor={selectedPatient}
      />
    </>
  );
}

export default Franchise;
