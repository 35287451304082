import React, { useEffect, useState } from "react";
import { storage } from "../../dependencies/store/storage";
import { ROLES } from "../../constants/role";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependiencies/TableColumn";
import { getItemList, updateOrder } from "./dependiencies/action";
import { useNavigate } from "react-router-dom";
import DownloadServiceData from "./DownloadServiceData/DownloadServiceData";
function ServiceRequest(props) {
  const { stateList } = useSelector((state) => state.stateCity);
  const [city, setCity] = useState([]); // Define city state
  const [printPage, setPrintPage] = useState(false)
  const navigate = useNavigate();

  const { ItemList, ItemTotalPage } = useSelector(
    (state) => state.serviceRequest
  );
  const role = storage.getUserRole();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    name: "",
    mobile: "",
    status: "",
    paymentStatus: "",
    month: "",
    year: "",
    fromDate: "",
    toDate: "",
    state: "",
    city: "",
    pinCode: "",
    search: ""
  });
  const handleEdit = (id, action) => {
    switch (action) {
      case 'payment':
        dispatch(updateOrder(id, { paymentStatus: 'paid' })).then(res => {
          dispatch(getItemList(query))
        })
        break;
      case 'service':
        dispatch(updateOrder(id, { status: 'completed' })).then(res => {
          dispatch(getItemList(query))
        })
        break;
      default:
        break;
    }
  };
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };
  useEffect(() => {
    dispatch(getItemList(query));
  }, [dispatch, query]);

  const handleCityChange = (value) => {
    setQuery({ ...query, city: value });
    console.log(value, "value-----");
  };

  const sortedStateList = stateList.slice().sort((a, b) => {

    const stateA = a.state.toUpperCase();
    const stateB = b.state.toUpperCase();

    if (stateA < stateB) {
      return -1;
    }
    if (stateA > stateB) {
      return 1;
    }
    return 0;
  });
  const handleSearch = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };
  const handleStateChange = (e) => {
    let stateName = e.target.value;
    let selectedState = stateList.find((state) => state.state === stateName);
    if (selectedState) {
      let sortedCities = selectedState.city.slice().sort((a, b) => {
        const cityA = a.toUpperCase();
        const cityB = b.toUpperCase();

        if (cityA < cityB) {
          return -1;
        }
        if (cityA > cityB) {
          return 1;
        }
        return 0;
      });
      setCity(sortedCities);
      setQuery({ ...query, state: stateName });
      console.log("state", stateName);
    }
  };
  const handleClearFilters = () => {
    setQuery({
      ...query,
      name: "",
      mobile: "",
      month: "",
      year: "",
      fromDate: "",
      toDate: "",
      state: "",
      city: "",
      status: "",
      paymentStatus: "",
      pinCode: "",
      search: ""

    });
  };
  const handlePrint = () => {
    setPrintPage(true);
    navigate('/downloadServiceData');

  };
  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Service Request</h4>
            </div>
            <div
              className="col-sm-8 col-9 text-right m-b-20"
              style={{ textAlign: "end" }}
            >
              {ROLES.PATIENT == role && (
                <Link
                  to="/services"
                  className="btn btn btn-primary btn-rounded float-right"
                >
                  <i className="fa fa-plus"></i> Book Service
                </Link>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="row m-b-20">
                <div className="form-group col-lg-3 mt-3">
                  <label>Search By Name</label>

                  <input
                    className="form-control"
                    name="name"
                    placeholder="Name"
                    value={query.name}
                    onChange={handleSearch}
                  />
                </div>
                <div className="form-group col-lg-3 mt-3">
                  <label>Search By Mobile</label>
                  <input
                    className="form-control"
                    name="mobile"
                    value={query.mobile}
                    placeholder="Mobile"
                    onChange={handleSearch}
                  />
                </div>
                <div className="form-group col-lg-3 mt-3">
                  <label>Search By Pincode</label>
                  <input
                    className="form-control"
                    name="pinCode"
                    value={query.pinCode}
                    placeholder="Pincode"
                    onChange={handleSearch}
                  />
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>From Date</label>
                  <input
                    type="date"
                    name="fromDate"
                    className="form-control"
                    value={query.fromDate}
                    onChange={handleSearch}
                  />
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>To Date</label>
                  <input
                    type="date"
                    name="toDate"
                    className="form-control"
                    value={query.toDate}
                    min={query.fromDate}
                    onChange={handleSearch}
                  />
                </div>
                {/* <div className="row m-b-20"> */}
                <div className="form-group col-md-3 mt-3">

                  <label>Search By Month</label>
                  <select
                    className="form-select"
                    value={query.month}
                    onChange={(e) => setQuery({ ...query, month: e.target.value })}
                  >
                    <option value="">--Select Month--</option>
                    <option value="January">January</option>
                    <option value="February">February</option>
                    <option value="March">March</option>
                    <option value="April">April</option>
                    <option value="May">May</option>
                    <option value="June">June</option>
                    <option value="July">July</option>
                    <option value="August">August</option>
                    <option value="September">September</option>
                    <option value="October">October</option>
                    <option value="November">November</option>
                    <option value="December">December</option>
                  </select>
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>Search By Year</label>
                  <select
                    className="form-select"
                    value={query.year}
                    onChange={(e) => setQuery({ ...query, year: e.target.value })}
                  >
                    <option value="">--Select Year--</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                    {/* Add more years as needed */}
                  </select>
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label> Search By State</label>
                  <select
                    name="state"
                    value={query.state}
                    onChange={(e) => handleStateChange(e)}
                    className="form-select">
                    <option value="">Select State</option>
                    {sortedStateList &&
                      sortedStateList.map((itm, index) => (
                        <option value={itm.state} key={index}>
                          {itm.state}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>Search By City</label>
                  <select
                    name="city"
                    value={query.city}
                    onChange={(e) => handleCityChange(e.target.value)}
                    className="form-select">
                    <option value="">Select City</option>
                    {city &&
                      city.map((cit, index) => (
                        <option value={cit} key={index}>
                          {cit}
                        </option>
                      ))}
                  </select>
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>Search By Staus</label>
                  <select
                    className="form-select"
                    value={query.status}
                    onChange={(e) => setQuery({ ...query, status: e.target.value })}
                  >
                    <option value="">--Select Status--</option>
                    <option value="">All</option>
                    <option value="pending">Pending</option>
                    <option value="completed">Completed</option>
                    <option value="canceled">Cancelled</option>
                  </select>
                </div>
                <div className="form-group col-md-3 mt-3">
                  <label>Search By Payment Staus</label>
                  <select
                    className="form-select"
                    value={query.paymentStatus}
                    onChange={(e) => setQuery({ ...query, paymentStatus: e.target.value })}
                  >
                    <option value="">--Select Status--</option>
                    <option value="">All</option>
                    <option value="paid">Paid</option>
                    <option value="pending">Pending</option>

                  </select>
                </div>
                <div className="col-4">
                  <button
                    className="btn btn-sm btn-secondary"
                    style={{ marginTop: "27px", marginRight: "10px" }}
                    onClick={handleClearFilters}
                    type="button"
                  >
                    Clear Filters
                  </button>
                </div>



                {/* </div> */}
              </div>
              <div className="form-group col-lg-3 mt-3">
                <label>Search</label>
                <input
                  className="form-control"
                  name="search"
                  placeholder="Search"
                  value={query.search}
                  onChange={handleSearch}
                />
              </div>
              <button className="btn btn-sm btn-secondary mb-1 mt-4 mx-2" onClick={handlePrint}>
                Print
              </button>
              {printPage && <DownloadServiceData ItemList={ItemList} />}
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        handleEdit,
                        pageNo: query.page - 1,
                        size: query.size,
                      })}
                      dataTable={ItemList}
                      totalRecord={ItemTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceRequest;
