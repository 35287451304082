// import React, { useEffect, useState } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { ROLES } from "../../constants/role";
// import Select from "react-select";
// import { storage } from "../../dependencies/store/storage";
// import { updateSlot } from "../EditProfile/dependiencies/action";
// import { getDocumentLink } from "../../dependencies/utils/helper";
// import { updateAccount } from './dependiencies/action'
// import { isEmpty } from "lodash";


// function Setting(props) {
//   const [form, setForm] = useState({});
//   const { user } = useSelector((state) => state.login);
//   const [options, setOption] = useState([]);
//   const [error, setError] = useState({})
//   const dispatch = useDispatch();

//   const role = storage.getUserRole();
//   useEffect(() => {
//     setForm(user);
//   }, [user]);

//   useEffect(() => {
//     const startTime = new Date("2000-01-01 12:00 AM");
//     const endTime = new Date("2000-01-02 12:00 AM");
//     const interval = 15;

//     let currentTime = startTime;

//     while (currentTime < endTime) {
//       const formattedTime = currentTime.toLocaleTimeString([], {
//         hour: "2-digit",
//         minute: "2-digit",
//       });

//       const addedMunite = currentTime.setMinutes(
//         currentTime.getMinutes() + interval
//       );

//       const extraFormated = new Date(addedMunite).toLocaleTimeString([], {
//         hour: "2-digit",
//         minute: "2-digit",
//       });

//       setOption((old) => [
//         ...old,
//         {
//           value: `${formattedTime} ${extraFormated}`,
//           label: `${formattedTime} ${extraFormated}`,
//         },
//       ]);
//     }
//   }, []);


//   const handleTimeSlotChange = (value) => {
//     setForm({ ...form, timeSlot: value });
//   };
//   const handleTimeSlotChangeOffline = (value) => {
//     setForm({ ...form, timeSlotoffline: value });
//   }
//   const handleSubmit = (e) => {
//     e.preventDefault();
//     dispatch(updateSlot({ timeSlot: form.timeSlot, timeSlotoffline: form.timeSlotoffline }));
//   };

//   const validator = () => {
//     let errors = {};
//     if (!form.accountNumber) {
//       errors = { ...errors, accountNumber: "This field is required!" };
//     }
//     if (!form.ifsc) {
//       errors = { ...errors, ifsc: "This field is required!" };
//     }
//     if (!form.bankName) {
//       errors = { ...errors, bankName: "This field is required!" };
//     }
//     if (!form.customerName) {
//       errors = { ...errors, customerName: "This field is required!" };
//     }
//     if (!form.branchName) {
//       errors = { ...errors, branchName: "This field is required!" };
//     }
//     if (!form.upiId) {
//       errors = { ...errors, upiId: "This field is required!" };
//     }
//     if (!form.upiNumber) {
//       errors = { ...errors, upiNumber: "This field is required!" };
//     } else if (form.newPassword !== form.confirmPassword) {
//       errors = {
//         ...errors,
//         confirmPassword: "New password and confirm password is not same!",
//       };
//     }

//     setError(errors);

//     return errors;
//   };

//   useEffect(() => {
//     setForm(user);
//     const doctorId = user._id
//     console.log("ghjkl", user._id);
//     fetch(`https://api.fever99.com/api/doctor-detail/${doctorId}`)
//       .then(response => {
//         if (!response.ok) {

//           throw new Error('Network response was not ok');
//         }
//         return response.json();
//       })
//       .then(data => {
//         setForm(data.data.extraDetail);
//         console.log("Fetched Doctor Details:", data.data.extraDetail);
//       })
//       .catch(error => {
//         console.error("Error fetching doctor details:", error);
//       });
//   }, [user, props]);


//   const handleInputChange = (name, value) => {
//     console.log("value----------", name);
//     setForm({ ...form, [name]: value });
//   };

//   const handleBankDetail = (e) => {
//     e.preventDefault();
//     let validate = validator();
//     if (!isEmpty(validate)) {
//       return false
//     }
//     dispatch(updateAccount({
//       accountNumber: form.accountNumber,
//       bankName: form.bankName,
//       customerName: form.customerName,
//       branchName: form.branchName,
//       upiId: form.upiId,
//       upiNumber: form.upiNumber,
//       ifsc: form.ifsc,
//     }));
//   };

//   return (
//     <>
//       <div class="page-wrapper">
//         <div class="content">
//           <div class="row">
//             <div class="col-sm-12">
//               <h4 class="page-title">Settings</h4>
//             </div>
//           </div>
//           <form onSubmit={(e) => handleSubmit(e)}>
//             <div class="card-box">
//               <h3 class="card-title">Basic Information</h3>
//               <div class="row">
//                 <div class="col-md-12">
//                   <div class="profile-img-wrap">
//                     <img className="inline-block" src={form && form.image ? getDocumentLink(form.image) : ""} alt="user" />
//                   </div>
//                   <div class="profile-basic">
//                     <div class="row">
//                       <div class="col-md-6 mt-2">
//                         <label class="focus-label">Name</label>
//                         <div class="form-group form-focus">
//                           <input
//                             type="text"
//                             readOnly
//                             name="name"
//                             class="form-control floating"
//                             value={form.name}
//                           />
//                         </div>
//                       </div>
//                       {ROLES.DOCTOR === role && (
//                         <div class="col-md-6 mt-2">
//                           <label class="focus-label">
//                             Appointment per charge Rs.
//                           </label>
//                           <div class="form-group form-focus">

//                             <input
//                               type="text"
//                               name="serviceCharge"
//                               readOnly
//                               class="form-control floating"
//                               value={form.serviceCharge}
//                             />
//                           </div>
//                         </div>
//                       )}

//                       <div className="col-md-6 mt-2">
//                         <label class="focus-label">Email</label>
//                         <div class="form-group form-focus">
//                           <input
//                             type="text"
//                             class="form-control floating"
//                             value={form.email}
//                             readOnly
//                           />
//                         </div>
//                       </div>
//                       <div class="col-md-6 mt-2">
//                         <label class="focus-label">Gendar</label>
//                         <div class="form-group form-focus select-focus">
//                           <input
//                             value={form.gender}
//                             name="gender"
//                             type="text"
//                             className="form-control"
//                           />
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             {(role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.PATIENT) && (
//               <>
//                 <div class="card-box">
//                   <h3 class="card-title">Bank Info</h3>
//                   <div class="row">
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">Account Number</label>
//                       <div class="form-group form-focus">
//                         <input
//                           type="number"
//                           name="accountNumber"
//                           class="form-control floating"
//                           value={form.accountNumber}
//                           onChange={(e) => handleInputChange(e.target.name, e.target.value)}
//                         />
//                       </div>
//                       {error && error.accountNumber && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.accountNumber}
//                         </span>
//                       )}
//                     </div>
//                     <div className="col-md-6 mt-2">
//                       <label class="focus-label">IFSC</label>
//                       <div class="form-group form-focus">
//                         <input
//                           type="text"
//                           name="ifsc"
//                           class="form-control floating"
//                           value={form.ifsc}
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                       {error && error.ifsc && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.ifsc}
//                         </span>
//                       )}
//                     </div>
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">Bank Name</label>
//                       <div class="form-group form-focus select-focus">
//                         <input
//                           value={form.bankName}
//                           name="bankName"
//                           type="text"
//                           className="form-control"
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                       {error && error.bankName && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.bankName}
//                         </span>
//                       )}
//                     </div>
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">Customer Name</label>
//                       <div class="form-group form-focus select-focus">
//                         <input
//                           value={form.customerName}
//                           name="customerName"
//                           type="text"
//                           className="form-control"
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                       {error && error.customerName && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.customerName}
//                         </span>
//                       )}
//                     </div>
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">Branch Name</label>
//                       <div class="form-group form-focus select-focus">
//                         <input
//                           value={form.branchName}
//                           name="branchName"
//                           type="text"
//                           className="form-control"
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                       {error && error.branchName && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.branchName}
//                         </span>
//                       )}
//                     </div>
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">UPI id</label>
//                       <div class="form-group form-focus select-focus">
//                         <input
//                           value={form.upiId}
//                           name="upiId"
//                           type="text"
//                           className="form-control"
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                       {error && error.upiId && (
//                         <span className="text-danger d-inline-block w-100">
//                           {error.upiId}
//                         </span>
//                       )}
//                     </div>
//                     <div class="col-md-6 mt-2">
//                       <label class="focus-label">UPI Number</label>
//                       <div class="form-group form-focus select-focus">
//                         <input
//                           value={form.upiNumber}
//                           name="upiNumber"
//                           type="number"
//                           className="form-control"
//                           onChange={(e) =>
//                             handleInputChange(e.target.name, e.target.value)
//                           }
//                         />
//                       </div>
//                     </div>
//                     {error && error.upiNumber && (
//                       <span className="text-danger d-inline-block w-100">
//                         {error.upiNumber}
//                       </span>
//                     )}
//                   </div>

//                 </div>
//                 <div className="text-center m-t-20">
//                   <button
//                     class="btn btn-primary submit-btn"
//                     type="button"
//                     onClick={(e) => handleBankDetail(e)}
//                   >
//                     Submit
//                   </button>
//                 </div>
//               </>
//             )}
//             {role === ROLES.DOCTOR && (
//               <>
//                 <div class="card-box">
//                   <h3 class="card-title">
//                     Avaliable time slot For Appointment
//                   </h3>
//                   <div class="row">
//                     <div className="form-group mt-2">
//                       <label>Select Time Slot (Online)</label>
//                       <Select
//                         options={options}
//                         closeMenuOnSelect={false}
//                         isMulti
//                         value={form.timeSlot}
//                         onChange={(e) => handleTimeSlotChange(e)}
//                       />
//                     </div>

//                     <div className="form-group mt-2">
//                       <label>Select Time Slot (Offline)</label>
//                       <Select
//                         options={options}
//                         closeMenuOnSelect={false}
//                         isMulti
//                         value={form.timeSlotoffline}
//                         onChange={(e) => handleTimeSlotChangeOffline(e)}
//                       />
//                     </div>
//                   </div>
//                 </div>

//                 <div class="text-center m-t-20">
//                   <button
//                     class="btn btn-primary submit-btn"
//                     type="button"
//                     onClick={(e) => handleSubmit(e)}
//                   >
//                     Save
//                   </button>
//                 </div>
//               </>
//             )}
//           </form>
//         </div>
//       </div>
//     </>
//   );
// }

// export default Setting;


import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROLES } from "../../constants/role";
import Select from "react-select";
import { storage } from "../../dependencies/store/storage";
import { updateSlot } from "../EditProfile/dependiencies/action";
import { getDocumentLink } from "../../dependencies/utils/helper";
import { updateAccount } from './dependiencies/action'
import { isEmpty } from "lodash";



function Setting(props) {
  const [form, setForm] = useState({});
  const [extraDetail, setExtraDetail] = useState({})
  const { user } = useSelector((state) => state.login);
  const [options, setOption] = useState([]);
  const [error, setError] = useState({})
  const dispatch = useDispatch();

  const role = storage.getUserRole();
  // useEffect(() => {
  //   setForm(user);
  // }, [user]);

  useEffect(() => {
    const startTime = new Date("2000-01-01 12:00 AM");
    const endTime = new Date("2000-01-02 12:00 AM");
    const interval = 15;

    let currentTime = startTime;

    while (currentTime < endTime) {
      const formattedTime = currentTime.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      const addedMunite = currentTime.setMinutes(
        currentTime.getMinutes() + interval
      );

      const extraFormated = new Date(addedMunite).toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
      });

      setOption((old) => [
        ...old,
        {
          value: `${formattedTime} ${extraFormated}`,
          label: `${formattedTime} ${extraFormated}`,
        },
      ]);
    }
  }, []);
  const handleTimeSlotChange = (value) => {
    setForm({ ...form, timeSlot: value });
  };
  const handleTimeSlotChangeOffline = (value) => {
    setForm({ ...form, timeSlotoffline: value });
  }
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateSlot({ timeSlot: form.timeSlot, timeSlotoffline: form.timeSlotoffline }));
  };
  console.log('formeloo', form, "extra", extraDetail)

  const validator = () => {
    let errors = {};
    if (!extraDetail.accountNumber) {
      errors = { ...errors, accountNumber: "This field is required!" };
    }
    if (!extraDetail.ifsc) {
      errors = { ...errors, ifsc: "This field is required!" };
    }
    if (!extraDetail.bankName) {
      errors = { ...errors, bankName: "This field is required!" };
    }
    if (!extraDetail.customerName) {
      errors = { ...errors, customerName: "This field is required!" };
    }
    if (!extraDetail.branchName) {
      errors = { ...errors, branchName: "This field is required!" };
    }
    if (!extraDetail.upiId) {
      errors = { ...errors, upiId: "This field is required!" };
    }
    if (!extraDetail.upiNumber) {
      errors = { ...errors, upiNumber: "This field is required!" };
    } else if (form.newPassword !== form.confirmPassword) {
      errors = {
        ...errors,
        confirmPassword: "New password and confirm password is not same!",
      };
    }

    setError(errors);

    return errors;
  };

  useEffect(() => {
    // setForm(user);
    // const doctorId = user._id
    fetch(`https://api.fever99.com/api/doctor-detail/${user._id}`)
      .then(response => {
        if (!response) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        console.log(data, "data")
        setForm(data.data.data)
        setExtraDetail(data.data.extraDetail)

        // setForm(data.data.extraDetail);
        // setForm(data.data.timeSlot)
        console.log("Fetched Doctor Details:", data);
      })
      .catch(error => {
        console.error("Error fetching doctor details:", error);
      });
  }, [user, props]);


  // useEffect(() => {
  //   if (user && user._id) {
  //     fetchDoctorDetails(user._id);
  //   }
  // }, [user]);

  // const fetchDoctorDetails = async (doctorId) => {
  //   try {
  //     const response = await fetch(`https://api.fever99.com/api/doctor-detail/${doctorId}`);
  //     if (!response.ok) {
  //       throw new Error('Network response was not ok');
  //     }
  //     const data = await response.json();
  //     setForm(data.data.extraDetail);
  //     console.log("Fetched Doctor Details:", data);
  //   } catch (error) {
  //     console.error("Error fetching doctor details:", error);
  //   }
  // };



  const handleInputChange = (name, value) => {
    console.log("value----------", name);
    setExtraDetail({ ...extraDetail, [name]: value });
  };

  const handleBankDetail = (e) => {
    e.preventDefault();
    let validate = validator();
    if (!isEmpty(validate)) {
      return false
    }
    dispatch(updateAccount({
      accountNumber: extraDetail.accountNumber,
      bankName: extraDetail.bankName,
      customerName: extraDetail.customerName,
      branchName: extraDetail.branchName,
      upiId: extraDetail.upiId,
      upiNumber: extraDetail.upiNumber,
      ifsc: extraDetail.ifsc,
    }));
  };

  return (
    <>
      <div class="page-wrapper">
        <div class="content">
          <div class="row">
            <div class="col-sm-12">
              <h4 class="page-title">Settings</h4>
            </div>
          </div>
          <form onSubmit={(e) => handleSubmit(e)}>
            <div class="card-box">
              <h3 class="card-title">Basic Information</h3>
              <div class="row">
                <div class="col-md-12">
                  <div class="profile-img-wrap">
                    <img className="inline-block" src={form && form.image ? getDocumentLink(form.image) : ""} alt="user" />
                  </div>
                  <div class="profile-basic">
                    <div class="row">
                      <div class="col-md-6 mt-2">
                        <label class="focus-label">Name</label>
                        <div class="form-group form-focus">
                          <input
                            type="text"
                            readOnly
                            name="name"
                            class="form-control floating"
                            value={form.name}
                          />
                        </div>
                      </div>
                      {ROLES.DOCTOR === role && (
                        <div class="col-md-6 mt-2">
                          <label class="focus-label">
                            Appointment per charge Rs.
                          </label>
                          <div class="form-group form-focus">

                            <input
                              type="text"
                              name="serviceCharge"
                              readOnly
                              class="form-control floating"
                              value={form.serviceCharge}
                            />
                          </div>
                        </div>
                      )}

                      <div className="col-md-6 mt-2">
                        <label class="focus-label">Email</label>
                        <div class="form-group form-focus">
                          <input
                            type="text"
                            class="form-control floating"
                            value={form.email}
                            readOnly
                          />
                        </div>
                      </div>
                      <div class="col-md-6 mt-2">
                        <label class="focus-label">Gendar</label>
                        <div class="form-group form-focus select-focus">
                          <input
                            value={form.gender}
                            name="gender"
                            type="text"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {(role === ROLES.DOCTOR || role === ROLES.FRANCHISE || role === ROLES.PATIENT) && (
              <>
                <div class="card-box">
                  <h3 class="card-title">Bank Info</h3>
                  <div class="row">
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">Account Number</label>
                      <div class="form-group form-focus">
                        <input
                          type="number"
                          name="accountNumber"
                          class="form-control floating"
                          value={extraDetail.accountNumber}
                          onChange={(e) => handleInputChange(e.target.name, e.target.value)}
                        />
                      </div>
                      {error && error.accountNumber && (
                        <span className="text-danger d-inline-block w-100">
                          {error.accountNumber}
                        </span>
                      )}
                    </div>
                    <div className="col-md-6 mt-2">
                      <label class="focus-label">IFSC</label>
                      <div class="form-group form-focus">
                        <input
                          type="text"
                          name="ifsc"
                          class="form-control floating"
                          value={extraDetail.ifsc}
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      {error && error.ifsc && (
                        <span className="text-danger d-inline-block w-100">
                          {error.ifsc}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">Bank Name</label>
                      <div class="form-group form-focus select-focus">
                        <input
                          value={extraDetail.bankName}
                          name="bankName"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      {error && error.bankName && (
                        <span className="text-danger d-inline-block w-100">
                          {error.bankName}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">Customer Name</label>
                      <div class="form-group form-focus select-focus">
                        <input
                          value={extraDetail.customerName}
                          name="customerName"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      {error && error.customerName && (
                        <span className="text-danger d-inline-block w-100">
                          {error.customerName}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">Branch Name</label>
                      <div class="form-group form-focus select-focus">
                        <input
                          value={extraDetail.branchName}
                          name="branchName"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      {error && error.branchName && (
                        <span className="text-danger d-inline-block w-100">
                          {error.branchName}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">UPI id</label>
                      <div class="form-group form-focus select-focus">
                        <input
                          value={extraDetail.upiId}
                          name="upiId"
                          type="text"
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                      {error && error.upiId && (
                        <span className="text-danger d-inline-block w-100">
                          {error.upiId}
                        </span>
                      )}
                    </div>
                    <div class="col-md-6 mt-2">
                      <label class="focus-label">UPI Number</label>
                      <div class="form-group form-focus select-focus">
                        <input
                          value={extraDetail.upiNumber}
                          name="upiNumber"
                          type="number"
                          className="form-control"
                          onChange={(e) =>
                            handleInputChange(e.target.name, e.target.value)
                          }
                        />
                      </div>
                    </div>
                    {error && error.upiNumber && (
                      <span className="text-danger d-inline-block w-100">
                        {error.upiNumber}
                      </span>
                    )}
                  </div>

                </div>
                <div className="text-center m-t-20">
                  <button
                    class="btn btn-primary submit-btn"
                    type="button"
                    onClick={(e) => handleBankDetail(e)}
                  >
                    Submit
                  </button>
                </div>
              </>
            )}
            {role === ROLES.DOCTOR && (
              <>
                <div class="card-box">
                  <h3 class="card-title">
                    Avaliable time slot For Appointment
                  </h3>
                  <div class="row">
                    <div className="form-group">
                      <label>Select Time Slot (Online)</label>
                      <Select
                        options={options}
                        closeMenuOnSelect={false}
                        isMulti
                        value={form.timeSlot}
                        onChange={(e) => handleTimeSlotChange(e)}
                      />
                    </div>

                    <div className="form-group">
                      <label>Select Time Slot (Offline)</label>
                      <Select
                        options={options}
                        closeMenuOnSelect={false}
                        isMulti
                        value={form.timeSlotoffline}
                        onChange={(e) => handleTimeSlotChangeOffline(e)}
                      />
                    </div>
                  </div>
                </div>

                <div class="text-center m-t-20">
                  <button
                    class="btn btn-primary submit-btn"
                    type="button"
                    onClick={(e) => handleSubmit(e)}
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default Setting;
