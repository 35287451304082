export const API_URL = () => process.env.REACT_APP_API_BASE;


export const FILEUPLOAD = `${API_URL()}/api/file-upload`
export const LOGIN = `${API_URL()}/api/login`
export const PROCEED_LOGOUT = `${API_URL()}/api/proceed-to-logout`
export const LOGOUT = `${API_URL()}/api/logout`
export const REGISTER_DOCTOR = `${API_URL()}/api/doctor/register`
export const REGISTER_EXPERT = `${API_URL()}/api/expert/register`
export const UPDATE_EXPERt = `${API_URL()}/api/expert/update`
export const GET_FRANCHISE_BY_ID = `${API_URL()}/api/expert/getbyid`
export const REGISTER_USER = `${API_URL()}/api/user/register`
export const USER_UPDATE = `${API_URL()}/api/user/update`
export const REGISTER_CORDINATOR = `${API_URL()}/api/cordinator/register`
export const APPOINTMENT = `${API_URL()}/api/appointments`
export const APPOINTMENT_REJECT = `${API_URL()}/api/appointment-rejected`
export const APPOINTMENT_RESCHEDULE = `${API_URL()}/api/appointment/reschedule`
export const APPOINTMENT_TIME_SLOT = `${API_URL()}/api/time-slot`
export const APPOINTMENT_CANCLE = `${API_URL()}/api/appointment/canceled`
export const APPOINTMENT_CALL_STATUS = `${API_URL()}/api/appointments-call-status`
export const UPDATE_USER_BY_ID = `${API_URL()}/api/update-user-status`
export const PRESCRIPTION = `${API_URL()}/api/prescriptions`
export const CREATE_COMPLENT = `${API_URL()}/api/raise-complaints`
export const UPDATE_DOCTOR_BY_ADMIN = `${API_URL()}/api/doctor/update-by-admin`
export const APPOINTMENT_HISTORY = `${API_URL()}/api/add-appointment-history`
export const FOLLOW_UP_SCHEDULE = `${API_URL()}/api/appointments-floow-up`
export const GET_DOCTORS = `${API_URL()}/api/doctor`
export const UPDATE_DOCTOR = `${API_URL()}/api/doctor/update`
export const UPDATE_DOCTOR_SLOT = `${API_URL()}/api/profile/update`
export const GET_WALLET = `${API_URL()}/api/get/wallet`
export const ADD_WALLET = `${API_URL()}/api/add/wallet`
export const GET_SERVICES = `${API_URL()}/api/services`
export const GET_SERVICES_BY_SLUG = `${API_URL()}/api/service`
export const STATE_CITY = `${API_URL()}/api/state-city`
export const GET_REFERAL_USER = `${API_URL()}/api/expert/referal-used-user`
export const GET_USERS_LIST = `${API_URL()}/api/users`
export const GET_USERS = `${API_URL()}/api/get-users`

export const TEAMS = `${API_URL()}/api/team`
export const TESTMONIAL = `${API_URL()}/api/testimonial`
export const CASR_STUDY = `${API_URL()}/api/case-study`
export const BLOG = `${API_URL()}/api/blog`
export const DASHBOARD = `${API_URL()}/api/dashbord`
export const E_ELINIC_REQUEST = `${API_URL()}/api/e-clinic-request`

export const CAREER = `${API_URL()}/api/career`

export const SERVICE_REQUEST = `${API_URL()}/api/service-order`

export const STRIPE_PAYMENT_SESSION = `${API_URL()}/api/stripe/session-id`
export const VALIDATE_PAYMENT_STATUS = `${API_URL()}/api/stripe/payment-status`

export const MEDICINE = `${API_URL()}/api/medicines`

export const PRESCRIPTION_BY_ID = `${API_URL()}/api/prescription-by-id`
export const PRESCRIPTION_APPOINTMENT_ID = `${API_URL()}/api/prescription`

export const NOTIFICATION = `${API_URL()}/api/notifications/forweb`
// export const NOTIFICATION = `${API_URL()}/api/notifications`

export const USER_AVALIABLE_STATUS = `${API_URL()}/api/update-user-avaliable`

export const PASSWORD_CHANGE = `${API_URL()}/api/password-change`

export const PIN_DOCTOR = `${API_URL()}/api/doctor-pin`

export const GET_DOCTOR_REPORT = `${API_URL()}/api/doctor/report`

export const GET_FRANCHISE_REPORT = `${API_URL()}/api/franchise/report`

export const EARNINGS = `${API_URL()}/api/earnings`

export const ADMIN_EARNING = `${API_URL()}/api/admin-earning`

export const SENDNOTIFICATION = `${API_URL()}/api/notifyMeetingUsers`

export const INSURENCE = `${API_URL()}/api/add-insurence-service`

export const CCAVENUEINCRIPT = `${API_URL()}/api/cc-encript`

export const FORGOT_REQUEST_OTP = `${API_URL()}/api/forgotpassword`

export const RESET_PASSWORD = `${API_URL()}/api/reset-forgotpassword`

export const REQUEST_REGISTER_TOP = `${API_URL()}/api/temp-register`

export const AGORA_GENERATE_TOKEN = `${API_URL()}/api/agora-token-generate`
export const DELETE_USER = `${API_URL()}/api/delete/user-datas`

//new added 
export const CheckisLoggedInUrl = `${API_URL()}/api/is-user-loggedIn`
export const GET_INCOME_WALLET = `${API_URL()}/api/franchise-income`
export const GET_TIME_SLOT = `${API_URL()}/api/time-slot`
export const FEEDBACK = `${API_URL()}/api/feedback`
export const GET_FEEDBACK = `${API_URL()}/api/feedback`
export const INVOICE = `${API_URL()}/api/invoice`
export const TRANSACTION_INVOICE = `${API_URL()}/api/transaction-invoice`
export const ACCOUNTINFO = `${API_URL()}/api/account-information`
export const DELETE_FEEDBACK = `${API_URL()}/api/feedback`
export const DOCTOR_DETAIL = `${API_URL()}/api/doctor-detail`
export const CREATE_WITHDREAW = `${API_URL()}/api/withdraw`
export const GET_ALL_WITHDRAW = `${API_URL()}/api/withdraws`
export const DELETE_WITHDRAW = `${API_URL()}/api/withdraw/delete`
export const UPDATE_WITHDRAW = `${API_URL()}/api/withdraw/update`
export const SUPPORT = `${API_URL()}/api/raise-complaints`
export const UPDATE_DOCTOR_DETAIL = `${API_URL()}/api/update-doctor-detail`
export const Transaction_Tracking = `${API_URL()}/api/transaction-tracking`
export const FRANCHISE_WALLET_TRACKING=`${API_URL()}/api/franchise-wallet-tracking`
export const FRANCHISE_WALLET_Detail=`${API_URL()}/api/franchise-wallet-detail`