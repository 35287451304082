import {
    appointmentService
} from "./service";
import { SET_SERVICE } from "../../../constants/actionConstants";

import {
    SET_APPOINTMENT_LIST,
    SET_APPOINTMENT_TOTAL_PAGE,
    SET_DOCTORS_LIST,
    SET_DOCTOR_SPACILITY,
    SET_DOCTOR_TOTAL_PAGE,
    isLoading,
    SET_CALL_STATUS
} from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";

// export const getItemListWithStatus = (params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let getItemList = await appointmentService.getItemListWithStatus(params);
//     dispatch({
//         type: SET_APPOINTMENT_LIST,
//         payload: getItemList.data,
//     });
//     dispatch({
//         type: SET_APPOINTMENT_TOTAL_PAGE,
//         payload: getItemList.totalAppointments
//     })
//     dispatch(isLoading(false));
// };
export const getItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await appointmentService.getItemList(params);
    dispatch({
        type: SET_APPOINTMENT_LIST,
        payload: getItemList.data,
    });
    dispatch({
        type: SET_APPOINTMENT_TOTAL_PAGE,
        payload: getItemList.totalAppointments
    })
    dispatch(isLoading(false));
};
export const getItemListWithSatus = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await appointmentService.getItemListWithSatus(params);
    dispatch({
        type: SET_APPOINTMENT_LIST,
        payload: getItemList.data,
    });
    dispatch({
        type: SET_APPOINTMENT_TOTAL_PAGE,
        payload: getItemList.totalAppointments
    })
    dispatch(isLoading(false));
};

export const getItemListWithLoader = (params) => async (dispatch) => {
    let getItemList = await appointmentService.getItemList(params);
    dispatch({
        type: SET_APPOINTMENT_LIST,
        payload: getItemList.data,
    });
    dispatch({
        type: SET_APPOINTMENT_TOTAL_PAGE,
        payload: getItemList.totalAppointments
    })
};

export const sendNotificationObj = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let notification = await appointmentService.sendNotification(params);

    dispatch(isLoading(false));

};

export const getItemById = (params) => async (dispatch) => {
    dispatch(isLoading(true))

    let item = await appointmentService.getItemById(params);

    dispatch(isLoading(false))

    return item
}

export const getDoctorList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getDoctors = await appointmentService.getAlDoctors(params);
    dispatch({
        type: SET_DOCTORS_LIST,
        payload: getDoctors.data,
    });
    dispatch({
        type: SET_DOCTOR_TOTAL_PAGE,
        payload: getDoctors.totalItems,
    });
    dispatch({
        type: SET_DOCTOR_SPACILITY,
        payload: getDoctors.spacility,
    });
    dispatch(isLoading(false));
};

export const updateAppointment = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let updateitemById = await appointmentService.updateStatus(id, params);
    dispatch(isLoading(false))
    toast.success(updateitemById.message)
    return updateitemById
};

export const rejectAppointment = (id, params) => async (dispatch) => {
    dispatch(isLoading(true));
    let rejectitemById = await appointmentService.rejectStatus(id, params);
    dispatch(isLoading(false));
    toast.success(rejectitemById.message);
    return rejectitemById;

};

// export const cancleAppointment = (id, params) => async (dispatch) => {
//     dispatch(isLoading(true));
//     let canclelitemById = await appointmentService.cancleStatus(id, params);
//     dispatch(isLoading(false));
//     toast.success(canclelitemById.message);
//     return canclelitemById;

// };

export const cancleAppointment = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let cancel = await appointmentService.cancleStatus(id, params);
    const { status, message } = cancel
    if (status) {
        toast.success(message)

    } else {
        toast.error(message)
    }
    dispatch(isLoading(false))

    return status
}

// export const addFeedback = (params) => async (dispatch) => {
//     dispatch(isLoading(true))
//     let cancel = await appointmentService.feedbackStatus(params);
//     const { status, message } = cancel
//     if (status) {
//         toast.success(message)
//     } else {
//         toast.error(message)
//     }
//     dispatch(isLoading(false))

//     return status
// }

export const addFeedback = (params, appointmentId) => async (dispatch) => {
    dispatch(isLoading(true))
    let cancel = await appointmentService.feedbackStatus(params, appointmentId);
    dispatch(isLoading(false))
    toast.success(cancel.message)
    return cancel
}

// export const fileUpload = (params) => async (dispatch) => {
//     dispatch(isLoading(true))
//     let fileUp = await appointmentService.fileUplaod(params);
//     dispatch(isLoading(false))
//     if (fileUp.status) {
//         toast.success(fileUp.message)
//     } else {
//         toast.error(fileUp.message)
//     }

//     return fileUp
// }

export const updateCallStatus = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let updateitemCAll = await appointmentService.updateCallStatus(id, params);
    dispatch({
        type: SET_CALL_STATUS,
        payload: updateitemCAll.appointment

    })
    dispatch(isLoading(false))
    return updateitemCAll
};

export const createItem = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    let createItem = await appointmentService.createItem(params);
    const { status, message } = createItem
    if (status) {
        toast.success(message)
    } else {
        toast.error(message)
    }
    dispatch(isLoading(false))
    return createItem
}


export const fileUpload = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    let fileUp = await appointmentService.fileUplaod(params);
    dispatch(isLoading(false))
    if (fileUp.status) {
        toast.success(fileUp.message)
    } else {
        toast.error(fileUp.message)
    }

    return fileUp
}

export const addHistory = (id, params) => async (dispatch) => {
    await appointmentService.addHistory(id, params);
}

export const addPrescription = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    let createPresc = await appointmentService.addPrescription(params);
    dispatch(isLoading(false))
    toast.success(createPresc.message)
    return createPresc
}




export const UpdatePrescription = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let UpdatePresc = await appointmentService.UpdatePrescription(id, params);
    dispatch(isLoading(false))
    toast.success(UpdatePresc.message)
    return UpdatePresc
}

export const createComplet = (params) => async (dispatch) => {
    dispatch(isLoading(true))
    let CreateComplet = await appointmentService.createComplent(params);
    dispatch(isLoading(false))
    toast.success(CreateComplet.message)
    return CreateComplet
}

export const ScheduleFollowUp = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let CreateComplet = await appointmentService.scheduleFollowUo(id, params);
    dispatch(isLoading(false))
    toast.success(CreateComplet.message)
    return CreateComplet
}


export const Recheduleappoinment = (params, Id) => async (dispatch) => {
    dispatch(isLoading(true))
    let CreateComplet = await appointmentService.recheduleappoinment(params, Id);
    const { message, status } = CreateComplet
    if (status) {
        toast.success(message)
    } else {
        toast.error(message)
    }
    dispatch(isLoading(false))
    return CreateComplet
}




export const getMedicineList = (params) => async (dispatch) => {
    let Medicine = await appointmentService.getMedicineList(params);
    const { data } = Medicine
    const newMediccinList = data.map(item => ({
        label: `${item.name} (${item.combination})`,
        value: `${item.name} (${item.combination})`
    }));

    return newMediccinList
}

export const prescriptionById = (id) => async (dispatch) => {
    dispatch(isLoading(true))
    let Pres = await appointmentService.getPrescriptionById(id)
    dispatch(isLoading(false))
    return Pres
}

export const prescriptionAppointmentId = (id) => async (dispatch) => {
    dispatch(isLoading(true))
    let Pres = await appointmentService.getPrescriptionAptId(id)
    dispatch(isLoading(false))
    return Pres
}
export const VideoCallToken = (params) => async (dispatch) => {
    dispatch(isLoading(true))

    let Pres = await appointmentService.agoraGeneratetoken(params)

    dispatch(isLoading(false))
    return Pres
}
