import {
    ServiceRequestService
} from "./service";
import {    
    SET_COMPLAINT_LIST,SET_COMPLAINT_TOTALRECORD,SET_COMPLAINT_TOTAL_PAGES,
    isLoading,
} from "../../../constants/actionConstants";
import {
    toast
} from "react-toastify";

export const getItemList = (params) => async (dispatch) => {
    dispatch(isLoading(true));
    let getItemList = await ServiceRequestService.getItemList(params);
    dispatch({
        type: SET_COMPLAINT_LIST,
        payload: getItemList.raiseComplaints,
    });
    dispatch({
        type: SET_COMPLAINT_TOTALRECORD,
        payload: getItemList.totalData,
    });
    dispatch({
        type: SET_COMPLAINT_TOTAL_PAGES,
        payload: getItemList.totalPages,
    });
    dispatch(isLoading(false));
};

export const updateComplent = (id, params) => async (dispatch) => {
    dispatch(isLoading(true))
    let updateitemById = await ServiceRequestService.updateStatus(id, params);
    dispatch(isLoading(false))
    toast.success(updateitemById.message)
    return updateitemById
};
