import React, { useEffect, useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';
import { Button } from 'react-bootstrap';


function DownloadAppointmentData() {
  const { ItemList } = useSelector((state) => state.appointment);
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: 'Doctor Data',
  });
useEffect(()=>{
handlePrint()
})
  return (
    <div ref={componentRef} style={{ width: '100%', height: '100%', padding: '20px' }}>
      <div style={{ marginBottom: '20px' }}>
        <h1 style={{ textAlign: 'center', marginBottom: '20px' }}>Appointment Data</h1>
        <Button onClick={handlePrint} variant="primary" style={{ float: 'right' }}>Print</Button>
      </div>
      <table className="table table-striped">
        <thead>
          <tr>
            <th>S.No.</th>
            <th>Date & Time</th>
            <th>Appointment Id</th>
            <th>Patient Name</th>
            <th>Patient Mobile</th>
            <th>Age</th>
            <th>State</th>
            <th>City</th>
            <th>Consultation Mode</th>
            <th>Payment Mode</th>
            <th>Payment Status</th>
            <th>Doctor Name</th>
            <th>Specialization</th>
            <th>Appointment Status</th>
          </tr>
        </thead>
        <tbody>
          {ItemList.map((appointment, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>{appointment.createAt}</td>
              <td>{appointment.appointmentNumber}</td>
              <td>{appointment.patientName}</td>
              <td>{appointment.expert.mobile}</td>
              <td>{appointment.age}</td>
              <td>{appointment.state}</td>
              <td>{appointment.city}</td>
              <td>{appointment.mode}</td>
              <td>{appointment.paymentMode}</td>
              <td>{appointment.paymentStatus}</td>
              <td>{appointment.doctor.name}</td>
              <td>{appointment.doctor.specialization}</td>
              <td>{appointment.status}</td>

            </tr>
          ))}
        </tbody>
      </table>
      {/* <Button onClick={handlePrint}>Print</Button> */}
    </div>
  );
}

export default DownloadAppointmentData;
