import React, { useEffect, useState } from "react";
import { storage } from "../../dependencies/store/storage";
import { ROLES } from "../../constants/role";
import { Link } from "react-router-dom";
import Table from "../common/Table";
import { useDispatch, useSelector } from "react-redux";
import TableColumn from "./dependiencies/TableColumn";
import { deleteFeedback } from "./dependiencies/action";
import { getFeedbackList } from "./dependiencies/action";
import { toDate } from "date-fns";
// import AddEdit from './Update'

function Feedback() {
  const { FeedbackList, FeedbackTotalPage } = useSelector(
    (state) => state.Feedback
  );
  const role = storage.getUserRole();
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    page: 1,
    size: 10,
    filter: "",
    name: "",
    mobile: "",
    email: "",
    fromDate: "",
    toDate: ""
  });
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("")
  const [searchQuery, setSearchQuery] = useState("");
  console.log(searchQuery);
  
  const handlePageChange = (page) => {
    setQuery({ ...query, page: page });
  };

  useEffect(() => {
    dispatch(getFeedbackList(query));
  }, [dispatch, query]);
  console.log(query);

  const handleDelete = (id) => {
    dispatch(deleteFeedback(id)).then((res) => {
      dispatch(getFeedbackList(query));
    });
  };

  const handleSearch = (e) => {
    const { name, value } = e.target;
    setQuery({ ...query, [name]: value });
  };
  const handleClear = () => {
    setQuery({
      page: 1,
      size: 10,
      filter: "",
      name: "",
      mobile: "",
      email: "",
      fromDate: "",
      toDate: ""
    });
  };

  return (
    <>
      <head>
        <title>Fever99</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      </head>
      <div className="page-wrapper">
        <div className="content">
          <div
            className="row"
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <div className="col-sm-4 col-3">
              <h4 className="page-title">Feedback</h4>
              {/* <button className="btn btn-sm btn-success mb-3" onClick={() => setShowAddEdit(true)}>Add new</button> */}
            </div>
            <div className="row m-b-20">
              <div className="form-group col-lg-3 mt-3">
                <label>Search By Name</label>

                <input
                  className="form-control"
                  name="name"
                  placeholder="Name"
                  value={query.name}
                  onChange={handleSearch}
                />
              </div>
              <div className="form-group col-lg-3 mt-3">
                <label>Search By Email</label>
                <input
                  className="form-control"
                  name="email"
                  value={query.email}
                  placeholder="Email"
                  onChange={handleSearch}
                />
              </div>
              <div className="form-group col-lg-3 mt-3">
                <label>Search By Mobile</label>
                <input
                  className="form-control"
                  name="mobile"
                  value={query.mobile}
                  placeholder="Mobile"
                  onChange={handleSearch}
                />
              </div>
              <div className="form-group col-md-3 mt-3">
                <label>From Date</label>
                <input
                  type="date"
                  name="fromDate"
                  className="form-control"
                  value={query.fromDate}
                  onChange={handleSearch}
                />
              </div>
              <div className="form-group col-md-3 mt-3">
                <label>To Date</label>
                <input
                  type="date"
                  name="toDate"
                  className="form-control"
                  value={query.toDate}
                  min={fromDate}
                  onChange={handleSearch}
                />
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <button className="btn btn-sm btn-danger" onClick={handleClear}>
                    Clear
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body p-0">
                  <div className="table-responsive">
                    <Table
                      columns={TableColumn({
                        // handleEdit,
                        handleDelete,
                        pageNo: query.page - 1,
                        size: query.size,
                      })}
                      dataTable={FeedbackList}
                      totalRecord={FeedbackTotalPage}
                      onPageChange={handlePageChange}
                      onTableChange={() => { }}
                      keyField="_id"
                      sizePerPage={query.size}
                    // pageSizeChange={(value) => handlePageSizeChange(value)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <AddEdit
        show={showAddEdit}
        rowData={rowData}
        handleClose={(value) => handleCloseModal(value)}
        handleAdd={(data) => handleAddMedicine(data)}
        handleUpdate={(data) => handleUpdateMedicine(data)}
      /> */}
    </>
  );
}

export default Feedback;
