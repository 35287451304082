import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ handleUpdate, pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    // {
    //   dataField: "",
    //   text: "S.No",
    //   formatter: (cell, row, rowIndex) => {
    //     return pageNo * size + (rowIndex + 1);
    //   },
    // },
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return ((pageNo - 1) * size) + (rowIndex + 1);
      },
    },
    {
      dataField: "date",
      text: "Date & Time",
      sort: false,
      formatter: (cell, row) => {
        const indianDateTime = moment.utc(cell).utcOffset("+05:30").format("YYYY-MM-DD h:mm A");
        return indianDateTime;
      },
    },
    {
      dataField: "userId.name",
      text: "Name",
      hidden: role === ROLES.FRANCHISE || role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "userId.role",
      text: "Type",
      hidden: role === ROLES.FRANCHISE || role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        return cell.charAt(0).toUpperCase() + cell.slice(1).toLowerCase();
      },
    },
    {
      dataField: "userId.email",
      text: "Email",
      hidden: role === ROLES.FRANCHISE || role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "userId.mobile",
      text: "Mobile",
      hidden: role === ROLES.FRANCHISE || role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        return cell;
      }
    },
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "status",
      text: "Status",
      formatter: (cell, row, rowIndex) => {
        let className = "";
        if (cell === "approved") {
          className = "text-success border-success";
        } else if (cell === "rejected") {
          className = "text-danger border-danger ";
        }
        return (
          <div className={className}>
            {cell}
          </div>
        );
      },
    },
    // {
    //   dataField: "_id",
    //   text: "Action",
    //   hidden: role === ROLES.ADMIN,
    //   formatter: (cell, row, rowIndex) => {
    //     return (
    //       <div className="btn-group">
    //         <button
    //           type="button"
    //           className="btn btn-sm btn-danger"
    //           onClick={() => handleDelete(cell)}
    //         >
    //           Delete
    //         </button>
    //       </div>
    //     );
    //   },
    // },
    {
      dataField: "_id",
      text: "Action",
      hidden: role === ROLES.FRANCHISE || role === ROLES.DOCTOR,
      formatter: (cell, row, rowIndex) => {
        if (row.status === "Inprocess") {
          return (
            <>
              <div className="btn-group mx-1">
                <button
                  type="button"
                  className="btn btn-sm btn-success"
                  onClick={() => handleUpdate(cell, "Credited Successfully")}>
                  Approve
                </button>
              </div>
              <div className="btn-group mx-1">
                <button
                  type="button"
                  className="btn btn-sm btn-danger"
                  onClick={() => handleUpdate(cell, "Rejected")}>
                  Reject
                </button>
              </div>
            </>
          );
        } else {
          return null;
        }
      },
    },

  ];
};

export default TableColumn;