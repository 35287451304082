import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import Api from "../../dependencies/utils/Api";

function WalletTrackingModal({ show, handleClose, doctor, type }) {
  const [transactions, setTransactions] = useState([]);
  const [totalbalance, setTotalBalance] = useState([]);
  const [fromDate, setFromDate] = useState(""); // State for fromDate
  const [toDate, setToDate] = useState(""); // State for toDate
  const [page, setPage] = useState(1); // State for page number
  const [totalPages, setTotalPages] = useState(0); // State for total pages

  useEffect(() => {
    fetchTransactions();
  }, [doctor, fromDate, toDate, page]); // Include fromDate, toDate, and page in the dependency array

  const fetchTransactions = async () => {
    try {
      // Construct URL with query parameters including fromDate, toDate, and page
      const url = `https://api.fever99.com/api/get-income-by-admin/${doctor._id}?role=${doctor.role}&fromDate=${fromDate}&toDate=${toDate}&page=${page}&type=${type}`;
      // Fetch data from the API
      const response = await Api.GET(url);
      setTransactions(response.data.data);
      setTotalBalance(response.data.totalBalance);
      setTotalPages(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching transactions:", error);
    }
  };
  let modalTitle = "";
  switch (type) {
    case 'wallet':
      modalTitle = 'Franchise Wallet Transaction Details';
      break;
    case 'incomeWallet':
      modalTitle = 'Franchise Income Wallet Transaction Details';
      break;
    case 'doctorWallet':
      modalTitle = 'Doctor Wallet Transaction Details';
      break;
    default:
      modalTitle = 'Transaction Detail';
  }
  const clearFilter = () => {
    setFromDate("");
    setToDate("");
  };

  const changePage = (newPage) => {
    setPage(newPage);
  };
  const calculateSerialNumber = (index) => {
    return (page - 1) * 10 + index + 1;
  };
  useEffect(() => {
    setPage(1);
  }, [show]);

  return (
    <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>{modalTitle}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h3>Total Balance : {totalbalance}</h3>
        <div className="row m-b-20">
          <div className="col-4">
            <label>From Date</label>
            <input
              type="date"
              name="fromDate"
              value={fromDate}
              className="form-control"
              onChange={(e) => setFromDate(e.target.value)}
            />
          </div>
          <div className="col-4">
            <label>To Date</label>
            <input
              type="date"
              name="toDate"
              value={toDate}
              min={fromDate}
              className="form-control"
              onChange={(e) => setToDate(e.target.value)}
            />
          </div>
          <div className="col-4">
            <button className="btn btn-danger ml-2 mt-4" onClick={clearFilter}>
              Clear Filter
            </button>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th>S.No.</th>
              <th>Date & Time</th>
              <th>Type</th>
              <th>Amount</th>
              <th>Balance</th>
              <th>Message</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index}>

                <td>{calculateSerialNumber(index)}</td>

                {/* <td>{index + 1}</td> */}
                <td>{new Date(transaction.timestamp).toLocaleString('en-IN')}</td>
                <td>{transaction.type}</td>
                <td>{transaction.amount}</td>
                <td>
                  {transaction && transaction.remainingBalance !== undefined
                    ? transaction.remainingBalance.toFixed(2)
                    : ""}
                </td>
                <td>{transaction.message}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <nav>
          <ul className="pagination">
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(1)}>
                First
              </button>
            </li>
            {[...Array(totalPages).keys()].map((number) => {
              const pageNumber = number + 1;
              const isActive = pageNumber === page;
              const isNearCurrentPage =
                pageNumber === page ||
                pageNumber === page - 1 ||
                pageNumber === page + 1 ||
                pageNumber === page - 2 ||
                pageNumber === page + 2;

              if (isActive || isNearCurrentPage) {
                return (
                  <li key={number} className={`page-item ${isActive ? "active" : ""}`}>
                    <button className="page-link" onClick={() => changePage(pageNumber)}>
                      {pageNumber}
                    </button>
                  </li>
                );
              }
              return null;
            })}
            <li className="page-item">
              <button className="page-link" onClick={() => changePage(totalPages)}>
                Last
              </button>
            </li>
          </ul>
        </nav>

      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-danger" onClick={handleClose}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default WalletTrackingModal;
