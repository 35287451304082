import { SET_WITHDRAW_LIST_TOTAL_PAGE, SET_WITHDRAW_LIST } from "../../constants/actionConstants";

const initialState = {
    WithdrawList: [],
    WithdrawTotalPage: 0
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
    switch (action.type) {
        case SET_WITHDRAW_LIST:
            return { ...state, WithdrawList: action.payload };
        case SET_WITHDRAW_LIST_TOTAL_PAGE:
            return { ...state, WithdrawTotalPage: action.payload };
        default:
            return state;
    }
};
