import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ downloadInvoice, pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        console.log("cell ghjkl;-----------'", row);
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "trans_date",
      text: "Date & Time",
      sort: false,
      formatter: (cell, row) => {
        const date = new Date(cell);
        const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
        return formattedDate;
      },
    },
    // {
    //   dataField: "trans_date",
    //   text: "Date & Time",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return cell;
    //   },
    // },

    // {
    //   dataField: "trans_date",
    //   text: "Date & Time",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     const date = new Date(cell);
    //     let hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const seconds = date.getSeconds();
    //     const amOrPm = hours >= 12 ? "PM" : "AM";
    //     hours = hours % 12 || 12;
    //     const formattedDate = `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()} ${hours}:${minutes}:${seconds} ${amOrPm}`;
    //     return formattedDate;
    //   },
    // },
    // {
    //   dataField: "billing_name",
    //   text: "Name",
    //   sort: false,
    //   formatter: (cell, row) => {
    //     return cell === undefined || cell === "undefined" ? "" : cell;
    //   },
    // },
    {
      dataField: "billing_zip",
      text: "zip",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "amount",
      text: "Ammount",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "payment_mode",
      text: "Mode",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "order_status",
      text: "Status",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "status_message",
      text: "Message",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "_id",
      text: "Action",
      formatter: (cell, row, rowIndex) => {
        return (
          <div className="btn-group">
            <button
              type="button"
              className="btn btn-sm btn-success"
              onClick={() => downloadInvoice(cell)}
            >
              Invoice
            </button>
          </div>
        );
      },
    },
  ];
};

export default TableColumn;
