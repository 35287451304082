import api from "../../../dependencies/utils/Api";

import { INVOICE, TRANSACTION_INVOICE } from "../../../constants/ApplicationUrl";

export const invoice = {

    getinvoiceList(params) {
        return api.GET(`${INVOICE}?page=${params.currentPage}&size=${params.size}`)
            .then((response) => {
                console.log(response, "response invoiceka");
                const { data: { message, status, response: responseData, totalDocuments } = {} } = response;
                console.log("response876543-----", message, status);
                if (status) {
                    return { message, status, responseData, totalDocuments };
                } else {
                    return { message, status, responseData };
                }
            })
    },

    getInvoiceById(id) {
        return api.DOWNLOADGETPDF(`${TRANSACTION_INVOICE}/${id}`)
    },


}