import React from "react";
import moment from "moment";
import { storage } from "../../../dependencies/store/storage";
import { ROLES } from "../../../constants/role";

const TableColumn = ({ pageNo = 0, size = 10 }) => {
  const role = storage.getUserRole();
  return [
    {
      dataField: "",
      text: "S.No",
      formatter: (cell, row, rowIndex) => {
        return pageNo * size + (rowIndex + 1);
      },
    },
    {
      dataField: "timestamp",
      text: "Date & Time",
      formatter: (cell, row, rowIndex) => {
        return moment(cell).format("DD/MM/YYYY h:mm:ss a");
      },
    },
    {
        dataField: "order_id",
        text: "Order Id",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
      {
        dataField: "billing_name",
        text: "Customer Name",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
      
    {
      dataField: "amount",
      text: "Amount",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "payment_mode",
      text: "Payment Mode",
      formatter: (cell, row, rowIndex) => {
        return cell;
      },
    },
    {
      dataField: "order_status",
      text: "Payment Status",
      formatter: (cell, row, rowIndex) => {
        return (
          <span
            className={
              cell === "Success"
                ? "custom-badge status-green"
                : "custom-badge status-red"
            }
          >
            {cell}
          </span>
        );
      },
    },
    {
        dataField: "billing_email",
        text: "Customer Email",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
      {
        dataField: "billing_tel",
        text: "Customer Mobile",
        formatter: (cell, row, rowIndex) => {
          return cell;
        },
      },
  ];
};

export default TableColumn;
