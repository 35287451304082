import { Modal } from "react-bootstrap";
import Api from "../../../dependencies/utils/Api";
import React, { useState, useEffect } from "react";
import PrescriptionDetailsModal from "../PrescriptionDetailModal/PrescriptionDetailModal";

function DoctorDetailsModal({ show, handleClose, doctor }) {
  console.log(doctor, "doctor");
  const [data, setData] = useState([]);
  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const options = { year: "numeric", month: "2-digit", day: "2-digit" };
  const [selectedStatus, setSelectedStatus] = useState("");
  const [toDate, settoDate] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [totalAppointment, setTotalAppointment] = useState(0);
  const [detail, setDetail] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [year, setYear] = useState("");
  const [month, setMonth] = useState("");
  const [totalAppointments, setTotalAppointments] = useState(0);
  console.log(page, totalPages, "Page")
  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    fromDate: "",
    toDate: "",
    month: "",
    year: "",
    paymentStatus: "",
    status: "",
  });
  const [showPrescriptionModal, setShowPrescriptionModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const openPrescriptionModal = (appointment) => {
    setSelectedAppointment(appointment);
    setShowPrescriptionModal(true);
  };
  const handleYearChange = (e) => {
    setYear(e.target.value);
  };
  console.log(data, ">>>>");
  const handleMonthChange = (e) => {
    setMonth(e.target.value);
  };
  const handleApplyDate = (e) => {
    setQuery({ ...query, fromDate: fromDate, toDate: toDate });
  };

  useEffect(() => {
    setQuery({
      ...query,
      paymentStatus,
      status: selectedStatus,
      year: year,
      month: month,
    });
  }, [paymentStatus, selectedStatus, year, month]);

  useEffect(() => {
    if (show && doctor) {
      fetchAppointments();
    }
  }, [show, doctor, page, query]);

  useEffect(() => {
    setTotalAppointments(data.totalAppointments);
  }, [data]);

  const fetchAppointments = () => {
    Api.GET(
      `https://api.fever99.com/api/appointments/?role=${doctor.role}&userId=${doctor._id}&page=${page}&fromDate=${query.fromDate}&toDate=${query.toDate}&month=${query.month}&year=${query.year}&paymentStatus=${paymentStatus}&status=${query.status}`
    )
      .then((response) => {
        if (response && response.data && Array.isArray(response.data.data)) {
          setData(response.data.data);
          setTotalAppointment(response.data.totalAppointments);
          setDetail(response.data.userExtraDetail);
          setTotalPages(response.data.totalPages);
        } else {
          setData([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching doctor data: ", error);
      });
  };

  const handlePageChange = (newPage) => {
    setPage(newPage);
  };
  const handleClearFilters = () => {
    setFromDate("");
    settoDate("");
    setYear("");
    setMonth("");
    setSelectedStatus("");
    setPaymentStatus("");
    setQuery({
      ...query,
      month: "",
      year: "",
      formData: "",
      toDate: "",
    });
  };
  return (
    <>
      <Modal size="xl" show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Doctor Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Doctor details */}
          {doctor && (
            <div className="doctor-details" style={{ padding: "20px", border: "1px solid #ddd", background: "#f9f9f9", borderRadius: "10px" }}>
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong className="px-2">Name:</strong> {doctor?.name || ""}
                  </p>
                  <p>
                    <strong className="px-2">Email:</strong> {doctor?.email || ""}
                  </p>
                  <p>
                    <strong className="px-2">Mobile:</strong> {doctor?.mobile || ""}
                  </p>
                  <p>
                    <strong className="px-2">Gender:</strong> {doctor?.gender || ""}
                  </p>
                  <p>
                    <strong className="px-2">Address:</strong> {doctor?.address || ""}
                  </p>
                  <p>
                    <strong className="px-2">Role:</strong> {doctor?.role || ""}
                  </p>
                  <p>
                    <strong className="px-2">State:</strong>
                    {doctor?.state || ""}
                  </p>
                  <p>
                    <strong className="px-2">City:</strong>
                    {doctor?.city || ""}
                  </p>
                  <p>
                    <strong className="px-2">Bank Name:</strong>
                    {detail?.bankName || ""}
                  </p>
                  <p>
                    <strong className="px-2">Name in Bank</strong>
                    {detail?.customerName || ""}
                  </p>
                  <p>
                    <strong className="px-2">Account Number:</strong>
                    {detail?.accountNumber || ""}
                  </p>
                  <p>
                    <strong className="px-2">Branch Name:</strong>
                    {detail?.branchName || ""}
                  </p>
                  <p>
                    <strong className="px-2">IFSC Code:</strong>
                    {detail?.ifsc || ""}
                  </p>
                  <p>
                    <strong className="px-2">UPI ID:</strong>
                    {detail?.upiId || ""}
                  </p>
                  <p>
                    <strong className="px-2">UPI Number:</strong>
                    {detail?.upiNumber || ""}
                  </p>
                </div>

                {doctor && (
                  <div className="col-md-6">
                    {doctor.role === "DOCTOR" && (
                      <>
                        <p>
                          <strong className="px-2">Specialization:</strong>{" "}
                          {doctor.specialization || ""}
                        </p>
                        <p>
                          <strong className="px-2">Service Charge For Patient:</strong>{" "}
                          {doctor.serviceChargepatient || ""}
                        </p>
                        <p>
                          <strong className="px-2">Service Charge:</strong>{" "}
                          {doctor.serviceCharge ? doctor.serviceCharge : ""}
                        </p>
                        <p>
                          <strong className="px-2">Date Of Registration:</strong>{" "}
                          {doctor.createdAt ? new Date(doctor.createdAt).toLocaleString('en-IN', options) : ""}
                        </p>
                        <p>
                          <strong className="px-2">Available For Patient:</strong>{" "}
                          {doctor.availableForPatient || ""}
                        </p>
                        <p>
                          <strong className="px-2">Available For Franchise:</strong>
                          {doctor.availableForFranchise || ""}
                        </p>
                        <p>
                          <strong className="px-2">Degree:</strong>
                          {detail?.degree || ""}
                        </p>
                        <p>
                          <strong className="px-2">Registration Number:</strong>
                          {detail && detail.registrationNumber ? detail.registrationNumber : ""}
                        </p>
                        <p>
                          <strong className="px-2">Total Experience:</strong>
                          {detail && detail.totalExperience ? detail.totalExperience : ""}
                        </p>
                        <p>
                          <strong className="px-2">Current Organization:</strong>
                          {detail && detail.currentOrganization ? detail.currentOrganization : ""}
                        </p>
                        <p>
                          <strong className="px-2">Whatsapp Number:</strong>
                          {detail && detail.whatsappNumber ? detail.whatsappNumber : ""}
                        </p>
                        <p>
                          <strong className="px-2">Pan Number:</strong>
                          {detail && detail.panNumber ? detail.panNumber : ""}
                        </p>
                      </>
                    )}

                    {doctor.role === "FRANCHISE" && (
                      <>
                        <p>
                          <strong className="px-2">Service Charge For Patient:</strong>{" "}
                          {doctor.serviceCharge || ""}
                        </p>
                        <p>
                          <strong className="px-2">Date Of Registration:</strong>
                          {new Date(doctor?.createdAt).toLocaleDateString(
                            "en-GB",
                            options
                          ) || ""}
                        </p>
                        <p>
                          <strong className="px-2">Degree:</strong>
                          {detail?.degree || ""}
                        </p>
                        <p>
                          <strong className="px-2">Registration Number:</strong>
                          {detail?.registrationNumber || ""}
                        </p>
                        <p>
                          <strong className="px-2">Total Experience:</strong>{" "}
                          {detail?.totalExperience || ""}
                        </p>
                        <p>
                          <strong className="px-2">Clinic Name:</strong>
                          {detail?.clinicName || ""}
                        </p>
                        <p>
                          <strong className="px-2">Date Of Birth:</strong>
                          {new Date(detail?.dob).toLocaleDateString(
                            "en-GB",
                            options
                          ) || ""}
                        </p>
                        <p>
                          <strong className="px-2">Current Organization:</strong>
                          {detail?.currentOrganization || ""}
                        </p>
                        <p>
                          <strong className="px-2">Whatsapp Number:</strong>
                          {detail?.whatsappNumber || ""}
                        </p>
                        <p>
                          <strong className="px-2">Aadhar:</strong>
                          {detail && detail.aadharNumber && (
                            <a href={`https://api.fever99.com/${detail.aadharNumber}`} target="_blank" rel="noreferrer">
                              <img src={`https://api.fever99.com/${detail.aadharNumber}`} height={"100px"} alt="Doctor" />
                            </a>
                          )}
                        </p>
                        <p>
                          <strong className="px-2">Pan:</strong>
                          {detail && detail.panNumber && (
                            <a href={`https://api.fever99.com/${detail.panNumber}`} target="_blank" rel="noreferrer">
                              <img src={`https://api.fever99.com/${detail.panNumber}`} height={"100px"} alt="Doctor" />
                            </a>
                          )}
                        </p>
                        <p>
                          <strong className="px-2">Franchise Code:</strong>
                          {detail?.franchiseCode || ""}
                        </p>
                      </>
                    )}
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="col-Gender: {data && data.user && data.user.name}md-12 d-flex">
            <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 pr-0 bg-white">
              <div className="dash-widget d-flex">
                <span className="dash-widget-bg2">
                  <i class="fas fa-rupee-sign"></i>
                </span>
                <div
                  className="dash-widget-info float-left pl-2 "
                  style={{ marginLeft: "5px" }}
                >
                  <p>Total Appointments</p>
                  <h4>
                    {doctor && doctor.appointmentCount
                      ? doctor.appointmentCount
                      : 0}
                  </h4>
                </div>
              </div>
            </div>

            {doctor && doctor.role !== "PATIENT" && (
              <div className="col-md-6 col-sm-6 col-lg-6 col-xl-6 pr-0 bg-white">
                <div className="dash-widget d-flex">
                  <span className="dash-widget-bg1">
                    <i class="fas fa-rupee-sign"></i>
                  </span>
                  <div
                    className="dash-widget-info float-left pl-2 "
                    style={{ marginLeft: "5px" }}
                  >
                    <p>Total Earning</p>
                    <h4>Rs. {doctor ? doctor.totalIncome.toFixed(2) : 0}</h4>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="row m-b-20">
            <div className="col-4">
              <label>From Date</label>
              <input
                type="date"
                name="fromDate"
                value={fromDate}
                className="form-control"
                onChange={(e) => setFromDate(e.target.value)}
              />
            </div>
            <div className="col-4">
              <label>To Date</label>
              <input
                type="date"
                name="toDate"
                value={toDate}
                min={fromDate} // Set min date to fromDate
                className="form-control"
                onChange={(e) => settoDate(e.target.value)}
              />
            </div>
            <div className="col-4">
              <button
                className="btn btn-sm btn-info"
                style={{ marginTop: "27px" }}
                onClick={(e) => handleApplyDate(e)}
                type="button"
              >
                Apply
              </button>
            </div>
          </div>
          <div className="row m-b-20">
            <div className="col-4">
              <label>Search By Month</label>
              <select
                className="form-select"
                value={month}
                onChange={handleMonthChange}
              >
                <option value="">--Select Month--</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </select>
            </div>
            <div className="col-4">
              <label>Search By Year</label>
              <select
                className="form-select"
                value={year}
                onChange={handleYearChange}
              >
                <option value="">--Select Year--</option>
                <option value="2022">2022</option>
                <option value="2023">2023</option>
                <option value="2024">2024</option>
                {/* Add more years as needed */}
              </select>
            </div>
            <div className="col-4">
              <label>Search By Staus</label>
              <select
                className="form-select"
                value={selectedStatus}
                onChange={(e) => setSelectedStatus(e.target.value)}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="pending">Pending</option>
                <option value="confirmed">Confirmed</option>
                <option value="completed">Completed</option>
                <option value="rejected">Rejected</option>
                <option value="accepted">Accepted</option>
                <option value="reschedule">Reschedule</option>
                <option value="cancelled">Cancelled</option>
                <option value="refunded">Refunded</option>
              </select>
            </div>
            <div className="col-4">
              <label>Search By Payment Staus</label>
              <select
                className="form-select"
                value={paymentStatus}
                onChange={(e) => setPaymentStatus(e.target.value)}
              >
                <option value="">--Select Status--</option>
                <option value="">All</option>
                <option value="Paid">Paid</option>
                <option value="Unpaid">Unpaid</option>
                <option value="refunded">Refunded</option>
              </select>
            </div>
            <div className="col-4">
              <button
                className="btn btn-sm btn-secondary"
                style={{ marginTop: "27px", marginRight: "10px" }}
                onClick={handleClearFilters}
                type="button"
              >
                Clear Filters
              </button>
            </div>
          </div>
          <div className="col-md-12">
            <br />
            <table className="table table-responsive table-bordered">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Patient Name</th>
                  <th>Prescription</th>
                  <th>Consultation Mode</th>
                  <th>Payment Mode</th>
                  <th>Payment Status</th>
                  <th>Patient Age</th>
                  <th>Appointment Date</th>
                  <th>Appointment Status</th>
                </tr>
              </thead>
              <tbody>
                {data.map((appointment, index) => (
                  <tr key={appointment._id}>
                    <td>{(page - 1) * 10 + index + 1}</td>
                    <td>{appointment.patientName}</td>
                    <td>
                      <button
                        className="btn btn-link"
                        onClick={() => openPrescriptionModal(appointment)}
                      >
                        View Prescription
                      </button>
                    </td>
                    <td>{appointment.mode}</td>
                    <td>{appointment.paymentMode}</td>
                    <td>{appointment.paymentStatus}</td>
                    <td>{` ${appointment?.age === 0 || appointment?.age === undefined
                      ? ""
                      : `${appointment?.age} yr `
                      }${appointment?.months === 0 ||
                        appointment?.months === undefined
                        ? ""
                        : appointment?.age > 0 || appointment?.months > 0
                          ? `${appointment?.age > 0 ? " " : ""}${appointment?.months
                          } month`
                          : ""
                      } `}</td>
                    <td>{`${new Date(appointment.dateTime).toLocaleDateString(
                      "en-GB",
                      options
                    )} - ${appointment.selectedTimeSlot}`}</td>
                    <td>{appointment.status}</td>
                  </tr>
                ))}
                {data.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={8}>
                      <b>No appointments found!</b>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <div className="pagination justify-content-center mt-4">
            <button
              className="btn btn-primary mr-2"
              onClick={() => handlePageChange(page - 1)}
              disabled={page === 1}
            >
              Previous
            </button>
            <span className="px-3 mt-1">
              Showing rows {(page)} to{" "}
              {Math.min(page * 10, data.length)} of {totalAppointment}
            </span>
            <button
              className="btn btn-primary ml-2"
              onClick={() => handlePageChange(page + 1)}
              disabled={page === totalPages || totalPages === 0}>
              Next
            </button>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-sm btn-danger" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <PrescriptionDetailsModal
        show={showPrescriptionModal}
        handleClose={() => setShowPrescriptionModal(false)}
        appointment={selectedAppointment} // Pass selected appointment data to PrescriptionDetailsModal
      />
    </>
  );
}

export default DoctorDetailsModal;
