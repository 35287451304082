import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import MainHeader from '../../common/MainHeader';
import MainFooter from '../../common/MainFooter';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const OTPForm = () => {
  const [form, setForm] = useState({
    name: "",
    mobile: ""
  });
  const [showOTPForm, setShowOTPForm] = useState(false);
  const [otpVerified, setOtpVerified] = useState(false);

  const handleInput = (name, value) => {
    setForm({ ...form, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.fever99.com/api/delete-account-by-user', form);
      console.log('Response:', response.data);
      toast.success(response.data.message);
      setShowOTPForm(true);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to delete account');
    }
  };

  const handleOtpVerification = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://api.fever99.com/api/otp-verify', form);
      console.log('Response:', response.data);
      toast.success(response.data.message);
      setOtpVerified(true);
    } catch (error) {
      console.error('Error:', error);
      toast.error('Failed to delete account');
    }
  };

  return (
    <>
      <MainHeader />
      <div className="container mt-5">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <div className="card">
              <div className="card-body">
                {otpVerified ? ( // Show success message if OTP is verified
                  <div>
                    <p className="text-success text-center" style={{ fontSize: "500" }}>Your Account Is Deleted Successfully</p>
                  </div>
                ) : (
                  <div>
                    <h4 className="card-title text-center mb-4">{showOTPForm ? 'Enter OTP' : 'Enter Your Details'}</h4>
                    {!showOTPForm ? (
                      <form onSubmit={handleSubmit}>
                        <div className="form-group">
                          <label>Name:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.name}
                            name='name'
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                            required
                          />
                        </div>
                        <div className="form-group mt-2">
                          <label>Mobile:</label>
                          <input
                            type="tel"
                            className="form-control"
                            value={form.mobile}
                            name='mobile'
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                            required
                          />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block mt-2">Send OTP</button>
                      </form>
                    ) : (
                      <form onSubmit={handleOtpVerification}>
                        <div className="form-group">
                          <label>Enter OTP:</label>
                          <input
                            type="text"
                            className="form-control"
                            value={form.otp}
                            name='otp'
                            onChange={(e) => handleInput(e.target.name, e.target.value)}
                            required
                          />
                        </div>
                        <button type="submit" className="btn btn-primary btn-block mt-2">Verify OTP</button>
                      </form>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <MainFooter />
    </>
  );
};

export default OTPForm;
