import React, { useEffect } from "react";

const NotFound = ({ onNotFound }) => {

    useEffect(() => {
        if (onNotFound) {
            onNotFound();
        }
    }, [onNotFound]);
    
    return (
        <div style={styles.container}>
            <h1 style={styles.title}>404 Not Found</h1>
            <p style={styles.message}>The page you are looking for does not exist.</p>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    title: {
        fontSize: '3rem',
        color: '#333',
    },
    message: {
        fontSize: '1.5rem',
        color: '#666',
    },
};

export default NotFound;
