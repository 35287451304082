import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { Recheduleappoinment } from "../dependiencies/action";
// import { getappoinmenttimeslot } from "../dependiencies/action";
import { appointmentService } from "../dependiencies/service";
import { Form } from "react-router-dom";
import DatePicker from "react-datepicker";
import { GetTimeSlot } from "../../Dashboard/dependencies/action";
import { formatDate } from "date-fns";



function RecheduleAppoinment(props) {
  const { handleClose, show, doctorId, mymodes, Id, data } = props;
  const [form, setForm] = useState({});
  const [error, setError] = useState({});
  const [modedata, setModedata] = useState([]);

  useEffect(() => {
    if (Id) {
      setForm({ Id: Id });
    }
  }, [Id]);



  const dispatch = useDispatch();

  // useEffect(() => {
  //   if (form.dateTime) {
  //     getappoinmenttimeslot(doctorId, form.dateTime);
  //   }
  // }, [form.dateTime])



  // eslint-disable-next-line no-unused-vars
  // let showAppointmentData;

  // const getappoinmenttimeslot = async (doctorId, dateTime) => {
  //   try {
  //     let response = await appointmentService.appoinmenttimeslot(doctorId, dateTime);
  //     showAppointmentData = response.data; // Assigning data to the variable
  //     if (mymodes === "Video") {
  //       setModedata(response.data.extractedOnlineTimes)
  //     } else {
  //       setModedata(response.data.extractedOfflineTimes)
  //     }
  //   } catch (error) {
  //   }
  // };

  const [onlineSlot, setOnlineSlot] = useState([]);
  const [OfflineSlot, setOfflineSlot] = useState([]);

  //slect date from here it will be  good   GetTimeSlot
  const handleInputChange = (name, value) => {
    setForm({ ...form, [name]: value });

    if (name === "dateTime") {
      const formattedDate = formatDate(value);
      // console.log(formattedDate, "sarita")
      dispatch(GetTimeSlot(formattedDate, doctorId)).then((res) => {
        const { extractedOfflineTimes, extractedOnlineTimes } = res;
        setModedata(mymodes === "Video" ? extractedOnlineTimes : extractedOfflineTimes);
      });
    }
  };

  function formatDate(timestamp) {
    const date = new Date(timestamp);
    const day = date.getDate();
    const month = date.getMonth() + 1; // Adding 1 because getMonth() returns zero-based months
    const year = date.getFullYear(); // Getting the full year

    // Padding single digit day or month with leading zero if needed
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}-${formattedMonth}-${year}`;
  }

  // const handleInputChange = (name, value) => {
  //   setForm({ ...form, [name]: value });
  // };



  const validator = () => {
    let errors = {};
    if (!form.dateTime) {
      errors = { ...errors, dateTime: "This field is required!" };
    }
    if (!form.details) {
      errors = { ...errors, details: "This field is required!" };
    }
    setError(errors);

    return errors;
  };

  const handleSchedule = () => {
    if (!form.dateTime) {
      setError({ ...error, dateTime: "Date is required" });
      return; // Exit if date is not selected
    }

    const formattedDate = formatDate(form.dateTime);

    const params = {
      dateTime: formattedDate,
      selectedTimeSlot: form.selectedTimeSlot
    };
    dispatch(Recheduleappoinment(params, Id)).then((res) => {
      handleClose(false);
      setForm({});
    });
  };






  return (
    <>
      <Modal show={show} onHide={(e) => handleClose(false)} backdrop="static">
        <Modal.Header>
          <span>Rechedule Appoinment</span>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label>Date</label>
            <DatePicker
              className="form-control"
              minDate={new Date()}
              title="Select Date"
              name="dateTime"
              selected={form.dateTime}
              placeholderText="Select Date"
              onChange={(e) => {
                handleInputChange("dateTime", e);
                // validator()

                let validate = validator();

                if (!isEmpty(validate)) {
                  return true;
                }

              }}
            />
            {error && error.title && (
              <span className="text-danger">{error.title}</span>
            )}
          </div>
          {/* <div className="form-group">
            <label>Slot</label>
            <select className="form-select" name="selectedTimeSlot" onChange={(e) => handleInputChange(e.target.name, e.target.value)}>
              <option value="">--Select One--</option>
              {
                modedata && modedata.map((d, index) => {
                  return (
                    <option className="text-dark" value={d.value} key={index}>{d}</option>
                  );
                })
              }
            </select>
            {error && error.details && (
              <span className="text-danger">{error.details}</span>
            )}
          </div> */}
          <div className="form-group">
            <label>Slot</label>
            <select
              className="form-select"
              name="selectedTimeSlot"
              onChange={(e) => handleInputChange(e.target.name, e.target.value)}
            >
              <option value="">--Select One--</option>
              {modedata && modedata.map((d, index) => (
                <option className="text-dark" value={d.label} key={index}>{d.label}</option>
              ))}
            </select>
            {error && error.details && (
              <span className="text-danger">{error.details}</span>
            )}
          </div>

        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-sm btn-danger"
            onClick={() => handleClose(false)}
          >
            Close
          </button>
          <button
            className="btn btn-sm btn-success"
            onClick={(e) => handleSchedule()}
          >
            Rechedule Appoinment
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RecheduleAppoinment;
